<template>
  <header>
    <nav
      style="--bs-breadcrumb-divider: '/'"
      aria-label="breadcrumb"
      class="breadcrumb-container"
    >
      <ol class="breadcrumb">
        <li class="breadcrumb-item" style="color: #dd9c44">
          <a href="/home">{{ $t("language.homeBtn") }}</a>
        </li>
        <li
          class="breadcrumb-item active"
          aria-current="page"
          style="color: #dd9c44"
        >
          <a href="devicelist">{{ $t("language.devList") }}</a>
        </li>
        <li
          class="breadcrumb-item active"
          aria-current="page"
          style="color: #dd9c44"
        >
          {{ $t("language.devTitle") }}
        </li>
      </ol>
    </nav>
  </header>
  <div class="row" id="users-row">
    <div class="outer-positioning">
      <div class="header-field">
        <div
          style="
            display: flex;
            flex-direction: row;
            align-items: center;
            align-content: center;
          "
        >
          <div>
            <GoldCircleButton
              :circleText="back"
              :circleImage="backImage"
              @circleClick="navigateBack()"
            />
          </div>

          <i
            class="fa-sharp fa-solid fa-microchip chip-img"
            style="color: #dd9c44; font-size: 1.5em"
          ></i>
          <span class="device-title">{{
            this.deviceArray.device_serial_num
          }}</span>
        </div>
        <div
          style="
            display: flex;
            flex-direction: row;
            align-items: center;
            align-content: center;
          "
          v-if="this.role"
        >
          <GoldCircleButton
            class="edit-button"
            :circleText="legEdit"
            :circleImage="editImage"
            @circleClick="navigateEdit()"
            v-if="this.role == 'admin'"
          />

          <!--           <GoldCircleButton
            :circleText="deleteText"
            :circleImage="deleteImage"
            @circleClick="showAlertModal = true"
            v-if="this.role == 'admin'"
          /> -->
        </div>
      </div>
      <div class="header-field2">
        <div
          style="
            display: flex;
            flex-direction: row;
            align-items: center;
            align-content: center;
          "
        >
          <i
            class="fa-sharp fa-solid fa-microchip chip-img"
            style="color: #dd9c44; font-size: 1.5em"
          ></i>
          <span class="device-title">{{
            this.deviceArray.device_serial_num
          }}</span>
        </div>
        <div
          style="
            display: flex;
            flex-direction: row;
            align-items: center;
            align-content: center;
          "
          v-if="this.role"
        >
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
            "
          >
            <div style="flex: 1">
              <GoldCircleButton
                :circleText="back"
                :circleImage="backImage"
                @circleClick="navigateBack()"
              />
            </div>
            <div style="flex: 1">
              <GoldCircleButton
                class="edit-button"
                :circleText="legEdit"
                :circleImage="editImage"
                @circleClick="navigateEdit()"
                v-if="this.role == 'admin'"
              />
            </div>
            <div style="flex: 1">
              <GoldCircleButton
                :circleText="deleteText"
                :circleImage="deleteImage"
                @circleClick="showAlertModal = true"
                v-if="this.role == 'admin'"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="device-diagram">
        <div
          v-if="screenWidth > 768"
          style="
            width: 40vw;
            height: 80vh;
            color: #fff;
            background-color: black;
            border-radius: 15px;
            margin-right: 20px;
            max-width: auto;
          "
        >
          <div class="mqtt-container">
            <div class="mqtt-data">
              <table>
                <tbody>
                  <tr v-for="item in this.mqttData" :key="item.id">
                    <td>{{ item }}<br /><br /><br /></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div style="height: 80vh">
          <div
            style="display: flex; flex-direction: column; align-items: center"
          >
            <h2 v-if="this.deviceLastLog[0]">
              {{ $t("language.physID") }}
              {{ this.deviceArray.device_physical_id }}
            </h2>
            <!-- <h2 style="padding-bottom: 20px">
              {{ $t("language.deviceStat") }}

             TRANSPORT
            </h2> -->
          </div>
          <div>
            <div class="outer-table">
              <table class="vehicle-table table-striped table">
                <thead>
                  <tr>
                    <th colspan="4">{{ $t("language.deviceDataTitle") }}</th>
                  </tr>
                </thead>
                <tbody v-if="this.deviceLastLog[0]">
                  <tr class="racer-info-data">
                    <td>{{ $t("language.deviceLat") }}</td>
                    <td>
                      {{ this.deviceLastLog[0].latitude.toFixed(6) + " °" }}
                    </td>
                    <td>{{ $t("language.deviceLon") }}</td>
                    <td>
                      {{ this.deviceLastLog[0].longitude.toFixed(6) + " °" }}
                    </td>
                  </tr>
                  <tr class="racer-info-data">
                    <td>{{ $t("language.deviceAlt") }}</td>
                    <td>
                      {{ this.deviceLastLog[0].altitude.toFixed(2) + " m" }}
                    </td>
                    <td>{{ $t("language.deviceBear") }}</td>
                    <td>{{ this.deviceLastLog[0].bearing + " °" }}</td>
                  </tr>
                  <tr class="racer-info-data">
                    <td>{{ $t("language.deviceSpeed") }}</td>
                    <td>{{ this.deviceLastLog[0].velocity + " km/h" }}</td>
                    <td>{{ $t("language.deviceG") }}</td>
                    <td>{{ this.deviceLastLog[0].g_force }}</td>
                  </tr>
                  <tr class="racer-info-data">
                    <td>{{ $t("language.carVolt") }}</td>
                    <td>{{ this.deviceLastLog[0].car_voltage }} V</td>
                    <td>{{ $t("language.deviceVolt") }}</td>
                    <td>{{ this.deviceLastLog[0].device_voltage }} V</td>
                  </tr>
                  <tr class="racer-info-data">
                    <td>{{ $t("language.deviceExt") }}</td>
                    <td>{{ this.deviceLastLog[0].external_temp + " °C" }}</td>
                    <td>{{ $t("language.deviceIntTemp") }}</td>
                    <td>{{ this.deviceLastLog[0].internal_temp + " °C" }}</td>
                  </tr>
                  <tr class="racer-info-data">
                    <td>{{ $t("language.deviceID") }}</td>
                    <td>{{ this.deviceLastLog[0].device_serial_num }}</td>
                    <td>{{ $t("language.deviceIMEI") }}</td>
                    <td>{{ this.deviceLastLog[0].imei }}</td>
                  </tr>
                  <tr class="racer-info-data">
                    <td>{{ $t("language.deviceMAC") }}</td>
                    <td>{{ this.deviceLastLog[0].mac_address }}</td>
                    <td>{{ $t("language.deviceConn") }}</td>
                    <td>{{ this.deviceLastLog[0].conn_type }}</td>
                  </tr>
                  <tr class="racer-info-data">
                    <td>{{ $t("language.deviceIP4") }}</td>
                    <td>{{ this.deviceLastLog[0].ip_4_address }}</td>
                    <td>{{ $t("language.deviceIPV6") }}</td>
                    <td>
                      {{ this.deviceLastLog[0].ip_6_address }}
                    </td>
                  </tr>
                  <tr class="racer-info-data">
                    <td>{{ $t("language.deviceSignal") }}</td>
                    <td>{{ this.deviceLastLog[0].signal_strength }}</td>
                    <td>{{ $t("language.deviceSpeeding") }}</td>
                    <td>{{ this.deviceLastLog[0].speeding }}</td>
                  </tr>
                  <tr class="racer-info-data">
                    <td>{{ $t("language.SOS") }}</td>
                    <td>{{ this.deviceLastLog[0].sos }}</td>
                    <td>{{ $t("language.OK") }}</td>
                    <td>{{ this.deviceLastLog[0].ok }}</td>
                  </tr>
                  <tr class="racer-info-data">
                    <td>{{ $t("language.deviceMedic") }}</td>
                    <td>{{ this.deviceLastLog[0].medic }}</td>
                    <td>{{ $t("language.deviceRoadCl") }}</td>
                    <td>{{ this.deviceLastLog[0].road_clear }}</td>
                  </tr>
                  <tr class="racer-info-data">
                    <td>{{ $t("language.deviceFire") }}</td>
                    <td>{{ this.deviceLastLog[0].fire }}</td>
                    <td>{{ $t("language.deviceRoadBl") }}</td>
                    <td>{{ this.deviceLastLog[0].road_blocked }}</td>
                  </tr>
                  <tr class="racer-info-data">
                    <td>{{ $t("language.deviceExtremeT") }}</td>
                    <td>{{ this.deviceLastLog[0].extreme_temp_warning }}</td>
                    <td>{{ $t("language.deviceExtremeA") }}</td>
                    <td>{{ this.deviceLastLog[0].extreme_accel_warning }}</td>
                  </tr>
                  <tr class="racer-info-data">
                    <td>{{ $t("language.deviceRecord") }}</td>
                    <td>{{ formatTimeDate(this.deviceLastLog[0].recorded_at) }} </td>
                    <td>{{ $t("language.deviceRec") }}</td>
                    <td>
                      {{
                        this.deviceLastLog[0].received_at ? formatTimeDate(this.deviceLastLog[0].received_at):this.deviceLastLog[0]? formatTimeDate(new Date()) : "-"
                      }}
                    </td>
                  </tr>
                </tbody>
                <tbody v-if="this.deviceLastLog[0] == undefined">
                  <tr class="racer-info-data">
                    <td>{{ $t("language.deviceLat") }}</td>
                    <td>_</td>
                    <td>{{ $t("language.deviceLon") }}</td>
                    <td>_</td>
                  </tr>
                  <tr class="racer-info-data">
                    <td>{{ $t("language.deviceAlt") }}</td>
                    <td>_</td>
                    <td>{{ $t("language.deviceBear") }}</td>
                    <td>_</td>
                  </tr>
                  <tr class="racer-info-data">
                    <td>{{ $t("language.deviceSpeed") }}</td>
                    <td>_</td>
                    <td>{{ $t("language.deviceG") }}</td>
                    <td>_</td>
                  </tr>
                  <tr class="racer-info-data">
                    <td>{{ $t("language.carVolt") }}</td>
                    <td>_</td>
                    <td>{{ $t("language.deviceVolt") }}</td>
                    <td>_</td>
                  </tr>
                  <tr class="racer-info-data">
                    <td>{{ $t("language.deviceExt") }}</td>
                    <td>_</td>
                    <td>{{ $t("language.deviceIntTemp") }}</td>
                    <td>_</td>
                  </tr>
                  <tr class="racer-info-data">
                    <td>{{ $t("language.deviceID") }}</td>
                    <td>_</td>
                    <td>{{ $t("language.deviceIMEI") }}</td>
                    <td>_</td>
                  </tr>
                  <tr class="racer-info-data">
                    <td>{{ $t("language.deviceMAC") }}</td>
                    <td>_</td>
                    <td>{{ $t("language.deviceConn") }}</td>
                    <td>_</td>
                  </tr>
                  <tr class="racer-info-data">
                    <td>{{ $t("language.deviceIP4") }}</td>
                    <td>_</td>
                    <td>{{ $t("language.deviceIPV6") }}</td>
                    <td>_</td>
                  </tr>
                  <tr class="racer-info-data">
                    <td>{{ $t("language.deviceSignal") }}</td>
                    <td>_</td>
                    <td>{{ $t("language.deviceSpeeding") }}</td>
                    <td>_</td>
                  </tr>
                  <tr class="racer-info-data">
                    <td>{{ $t("language.SOS") }}</td>
                    <td>_</td>
                    <td>{{ $t("language.OK") }}</td>
                    <td>_</td>
                  </tr>
                  <tr class="racer-info-data">
                    <td>{{ $t("language.deviceMedic") }}</td>
                    <td>_</td>
                    <td>{{ $t("language.deviceRoadCl") }}</td>
                    <td>_</td>
                  </tr>
                  <tr class="racer-info-data">
                    <td>{{ $t("language.deviceFire") }}</td>
                    <td>_</td>
                    <td>{{ $t("language.deviceRoadBl") }}</td>
                    <td>_</td>
                  </tr>
                  <tr class="racer-info-data">
                    <td>{{ $t("language.deviceExtremeT") }}</td>
                    <td>_</td>
                    <td>{{ $t("language.deviceExtremeA") }}</td>
                    <td>_</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div
          v-if="screenWidth < 768"
          style="
            width: 90vw;
            height: 60vh;
            padding-bottom: auto;
            color: white;
            background-color: black;
            overflow: hidden;
          "
        >
          <div class="mqtt-container">
            <div class="mqtt-data">
              <table>
                <tbody>
                  <tr v-for="item in this.mqttData" :key="item.id">
                    <td>{{ item }}<br /><br /><br /></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CustomAlert
      :show="showAlertModal"
      body="Biztosan törölni akarja ezt az elemet a nyílvántartásból?"
      @cancel="showAlertModal = false"
      @confirm="handleDeleteClick"
    />
  </div>
</template>

<script>
import mqtt from "mqtt/dist/mqtt";
import dataProvider from "../utils/dataProvider";
import GoldCircleButton from "./GoldCircleButton.vue";
import jwt_decode from "jwt-decode";
import CustomAlert from "./CustomAlert.vue";
const dp = dataProvider();
// const signaling_topic=process.env.SIGNALING_TOPIC || "signalingLog"
const frontend_raw_data_topic =
  process.env.VUE_APP_FRONTENDRAWDATA_TOPIC || "rawData";
const topic_prefix = process.env.VUE_APP_TOPIC_PREFIX || ""; //demo/
// const server_data_topic=process.env.SERVERTODEVICE_TOPIC ||"ddc-rally/serverData"
// const frontend_topic=process.env.FRONTEND_TOPIC|| "deviceData"
// const device_data_topic=process.env.DEVICEDATA_TOPIC|| "ddc-rally/deviceData"

export default {
  name: "Device",
  data() {
    return {
      wordDocumentUrl: "../adatvedelem.docx",
      backImage: "fa-solid fa-arrow-left",
      editImage: "fa-solid fa-edit",
      deleteImage: "fa-solid fa-trash-can",
      rows: [
        {
          id: 1,
          value1: "User",
          value2: "+3630989898",
          value3: "user@user.com",
          value4: "1",
        },
      ],
      screenWidth: window.innerWidth,
      deviceArray: [],
      deviceLastLog: [],
      back: "",
      deleteText: "",
      legEdit: "",
      //-------------------------------------Role Start---------------------------------------
      role: undefined,
      //-------------------------------------------------------------------------------------
      ///-------------------------------------------------------MQTT-----------------------------------------------------
      connection: {
        protocol: "wss",
        host: "rally.ddc.sze.hu",
        // ws: 8083; wss: 8084
        port: 8083,
        endpoint: "", // "/mqtt"
        rejectUnauthorized: false,

        // for more options, please refer to https://github.com/mqttjs/MQTT.js#mqttclientstreambuilder-options
        clean: true,
        connectTimeout: 30 * 1000, // ms
        reconnectPeriod: 4000, // ms
        clientId:
          "rally_frontend_" + Math.random().toString(16).substring(2, 8),
        // auth
        username: "",
        password: "",
      },
      subscription: {
        topic: "topic/mqttx",
        qos: 0,
      },
      publish: {
        topic: "topic/browser",
        qos: 0,
        payload: '{ "msg": "Hello, I am browser." }',
      },
      receiveNews: "",
      qosList: [0, 1, 2],
      client: {
        connected: false,
      },
      subscribeSuccess: false,
      connecting: false,
      retryTimes: 0,
      mqttData: [],
      showAlertModal: false,
      received_at: undefined,
      recorded_at: undefined,
      //------------------------------------------------------------MQTT END-----------------------------------------------------------------------------------------
    };
  },
  created() {
    this.legEdit = this.$t("language.legEdit");
    this.deleteText = this.$t("language.deleteText");
    this.back = this.$t("language.back");
  },
  async mounted() {
    window.addEventListener("resize", this.handleResize);

    this.device_id = localStorage.getItem("device_id");
    this.deviceArray = await dp.getDeviceById(this.device_id);
    this.deviceLastLog = await dp.getAllLastDevicelogsByDevice(this.device_id);
    this.initMQTTData();
    this.createMQTTConnection();
    this.doMQTTSubscribe(
      `${topic_prefix}${frontend_raw_data_topic}/${this.deviceArray.device_physical_id}`,
      0
    );
    //-------------------------------------Role Start---------------------------------------
    let token = localStorage.getItem("JWT");
    let decodedToken = jwt_decode(token);
    this.role = decodedToken[0].role_type;
    //-------------------------------------------------------------------------------------
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  components: { GoldCircleButton, CustomAlert },
  methods: {
    async remove() {
      await dp.deleteDeviceById(this.device_id);
      this.$router.push("/deviceList");
    },
    formatDate(date) {
      const formattedDate = new Date(date).toLocaleDateString("en-CA");
      return formattedDate;
    },
    formatTimeDate(datetime) {
      const formattedDate = new Date(datetime).toLocaleString();
      return formattedDate;
    },
    formatFullDate(timestamp) {
    const date = new Date(timestamp * 1000); 
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}. ${month}. ${day}. ${hours}:${minutes}:${seconds}`;
    },
    handleDeleteClick() {
      this.remove();
      this.showAlertModal = false;
    },
    navigateBack() {
      this.$router.push("/deviceList");
    },
    navigateEdit() {
      this.$router.push("/editdevice");
    },
    handleResize() {
      this.screenWidth = window.innerWidth;
    },
    //--------------------------------MQTT FUNCTIONS----------------------------------
    initMQTTData() {
      this.client = {
        connected: false,
      };
      this.retryTimes = 0;
      this.connecting = false;
      this.subscribeSuccess = false;
    },
    handleMQTTOnReConnect() {
      console.log("Mqtt reconnecting");
    },
    createMQTTConnection() {
      try {
        this.connecting = true;
        const { protocol, host, port, endpoint, ...options } = this.connection;
        const connectUrl = `${protocol}://${host}:${port}${endpoint}`;
        this.client = mqtt.connect(connectUrl, options);
        if (this.client.on) {
          this.client.on("connect", () => {
            this.connecting = false;
            console.log("Connection succeeded!");
          });
          this.client.on("reconnect", this.handleMQTTOnReConnect);
          this.client.on("error", (error) => {
            console.log("Connection failed", error);
          });
          this.client.on("message", (topic, message) => {
            let data = JSON.parse(message);
            if (topic.includes(frontend_raw_data_topic)) {
              this.mqttData.unshift(message.toString());
              let completeData = [
                {
                  altitude: data.deviceStatusPayload
                    ? data.deviceStatusPayload.gpsLocation.altitude
                    : "-",
                  bearing: data.deviceStatusPayload
                    ? data.deviceStatusPayload.gpsLocation.heading
                    : "-",
                  car_voltage: data.deviceStatusPayload
                    ? data.deviceStatusPayload.voltageLevels.vehicleVoltage
                    : "-",
                  conn_type: data.deviceStatusPayload
                    ? data.deviceStatusPayload.networkInfo.connType
                    : "-",
                  device_id: this.device_id,
                  device_voltage: data.deviceStatusPayload
                    ? data.deviceStatusPayload.voltageLevels.deviceVoltage
                    : "-",
                  external_temp: data.deviceStatusPayload
                    ? data.deviceStatusPayload.temperatures.externalTemp
                    : "-",
                  extreme_accel_warning: data.deviceStatusPayload
                    ? data.deviceStatusPayload.Flag_EXTREME_ACCEL_WARNING
                    : "-",
                  extreme_temp_warning: data.deviceStatusPayload
                    ? data.deviceStatusPayload.Flag_EXTREME_TEMP_WARNING
                    : "-",
                  fire: data.deviceStatusPayload
                    ? data.deviceStatusPayload.Flag_FIRE
                    : "-",
                  g_force: data.deviceStatusPayload
                    ? data.deviceStatusPayload.gpsLocation.g_force
                    : "-",
                  imei: data.deviceStatusPayload
                    ? data.deviceStatusPayload.networkInfo.imei
                    : "-",
                  internal_temp: data.deviceStatusPayload
                    ? data.deviceStatusPayload.temperatures.internalTemp
                    : "-",
                  ip_4_address: data.deviceStatusPayload
                    ? data.deviceStatusPayload.networkInfo.ip4Address
                    : "-",
                  ip_6_address: data.deviceStatusPayload
                    ? data.deviceStatusPayload.networkInfo.ip6Address
                    : "-",
                  latitude: data.deviceStatusPayload
                    ? data.deviceStatusPayload.gpsLocation.latitude
                    : "-",
                  longitude: data.deviceStatusPayload
                    ? data.deviceStatusPayload.gpsLocation.longitude
                    : "-",
                  mac_address: data.deviceStatusPayload
                    ? data.deviceStatusPayload.networkInfo.macAddress
                    : "-",
                  medic: data.deviceStatusPayload
                    ? data.deviceStatusPayload.Flag_MEDIC
                    : "-",
                  ok: data.deviceStatusPayloa
                    ? data.deviceStatusPayload.Flag_OK
                    : "-",
                  road_blocked: data.deviceStatusPayload
                    ? data.deviceStatusPayload.Flag_ROAD_BLOCKED
                    : "-",
                  signal_strength: data.deviceStatusPayload
                    ? data.deviceStatusPayload.networkInfo.signalStrength.value
                    : "-",
                  sos: data.deviceStatusPayload
                    ? data.deviceStatusPayload.Flag_SOS
                    : "-",
                  speeding: data.deviceStatusPayload
                    ? data.deviceStatusPayload.Flag_SPEEDING
                    : "-",
                  velocity: data.deviceStatusPayload
                    ? data.deviceStatusPayload.gpsLocation.velocity
                    : "-",
                  received_at: data.deviceStatusPayload
                    ? this.formatTimeDate(new Date())
                    : "-",
                    recorded_at: data.deviceStatusPayload ? 
                    this.formatFullDate(data.deviceStatusPayload.timestamp.value) : "-",
                },
              ];
              this.deviceLastLog = completeData;
            }
          });
        }
      } catch (error) {
        this.connecting = false;
        console.log("mqtt.connect error", error);
      }
    },

    doMQTTSubscribe(topic, qos) {
      //const { topic, qos } = this.subscription;
      this.client.subscribe(topic, { qos }, (error, res) => {
        if (error) {
          console.log("Subscribe to topics error", error);
          return;
        }
        this.subscribeSuccess = true;
        console.log("Subscribe to topics res", res);
      });
    },
    doMQTTUnSubscribe(topic) {
      //const { topic } = this.subscription;
      this.client.unsubscribe(topic, (error) => {
        if (error) {
          console.log("Unsubscribe error", error);
        }
      });
    },
    doMQTTPublish(topic, payload, qos) {
      //const { topic, qos, payload } = this.publish;
      this.client.publish(topic, payload, { qos }, (error) => {
        if (error) {
          console.log("Publish error", error);
        }
      });
    },
    destroyMQTTConnection() {
      if (this.client.connected) {
        try {
          this.client.end(false, () => {
            this.initMQTTData();
            console.log("Successfully disconnected!");
          });
        } catch (error) {
          console.log("Disconnect failed", error.toString());
        }
      }
    },
    downloadWordFile() {
      const link = document.createElement("a");

      link.href = this.wordDocumentUrl;
      link.download = "adatedelem.docx";
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    //---------------------------------------------------------MQTT ends here----------------------------------------
  },
};
</script>

<style scoped>
header {
  position: fixed;
  top: 0;
  left: 20px;
}
footer {
  position: absolute;
  bottom: 0;
}
.mqtt-container {
  width: 25wh;
  max-height: 80vh;
  border: 1px solid #ccc;
  padding: 10px;
}

.mqtt-data {
  max-height: 78vh;
  word-wrap: break-word;
  text-align: left;
  overflow-x: auto;
  overflow-y: fixed;
  scrollbar-width: thin;
  scrollbar-color: #dd9c44 transparent;
}

ol.breadcrumb li + li:before {
  color: rgb(255, 255, 255);
}

ol.breadcrumb li a {
  color: #dd9c44;
}

ol.breadcrumb li a:hover {
  color: white;
  text-decoration: underline;
}

#users-row {
  width: 100vw;
  position: absolute !important;
  top: 0;
  left: 0 !important;
  overflow: hidden;
  padding: 0;
  z-index: 1000;
  margin: 0;
  top: 35px;
  padding-inline: 3vw;
}

.edit-button {
  margin-right: 10px;
}

.outer-table::-webkit-scrollbar {
  width: 0px;
}
.breadcrumb-container {
  background-color: #202528;
  z-index: 9999999999 !important;
  position: fixed !important;
  max-height: 30px;
  border-radius: 10px;
  padding: 5px;
}

/* For Firefox */
.outer-table {
  scrollbar-width: thin;
  scrollbar-color: #dd9c44 transparent;
}
.privacyText {
  color: grey;
  text-align: center;
  margin-left: 20px;
}

.device-diagram {
  display: flex;
  flex-direction: row;
  width: 90vw;
  padding-top: 20px;
}

.vehicle-table th {
  position: sticky;
  top: 0;
}

.device-title {
  font-family: "Lexend";
  color: white;
  font-size: 26px;
  padding-left: 7px;
}

.chip-img {
  margin-left: 10px;
}

.custom-button {
  background-color: #dd9c44;
  border-radius: 40px;
  width: 80px;
  height: 30px;
  border: 0;
  color: white;
  font-family: Lexend;
  font-size: medium;
}

.header-field {
  width: 94vw;
  background-color: #2c3033;
  border-radius: 15px;
  height: auto;
  display: flex;
  flex-direction: row;
  padding: 10px;
  justify-content: space-between;
  margin-top: 6px;
}

.header-field2 {
  width: 95vw;
  background-color: #2c3033;
  border-radius: 15px;
  height: auto;
  display: flex;
  flex-direction: row;
  padding: 10px;
  justify-content: space-between;
  margin-top: 20px;
  display: none;
}

.outer-positioning {
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
}

.outer-table {
  width: 100%;
  height: 75vh;
  overflow-y: auto;
  overflow-x: auto;
  background-color: transparent;
  border-radius: 10px;
  margin-bottom: 20px;
}

.vehicle-table {
  width: 50vw;
  border-collapse: collapse;
  margin-bottom: 10px;
}

.vehicle-table th {
  background-color: #dd9c44;
  color: black;
  border-bottom: 2px solid #2c3033;
  padding: 8px;
  font-family: "Lexend";
}

.vehicle-table td {
  border-bottom: 1px solid #2c3033;
  font-size: small;
  background: #202528;
  padding: 8px;
  text-align: left;
  color: white;
  font-family: "Lexend";
  padding-left: 2%;
  padding-right: 2%;
  width: 20%;
}

h2 {
  font-family: "Lexend";
  color: white;
  font-size: x-large;
  padding-top: 6px;
}

#middle-button {
  margin-inline: 15px;
}
@media (max-width: 500px) {
  .outer-table {
    width: 100%;
    overflow-y: auto;
    overflow-x: auto;
    height: 65vh;
    background-color: transparent;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  .privacyText {
    position: absolute;
    color: grey;
    text-align: center;
    margin-left: 0px;
    margin-top: 500px;
    width: 100vw;
  }
}
@media (max-width: 768px) {
  .device-title {
    font-size: 23px;
  }

  .header-field {
    display: none;
  }

  .header-field2 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .edit-button {
    margin-right: 0px;
  }

  .chip-img {
    margin-left: 0px;
  }

  h2 {
    font-size: 20px;
  }

  .vehicle-table {
    width: 90vw;
  }

  .vehicle-table td {
    font-size: 11px;
  }

  .device-diagram {
    display: grid;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80vw;
  }

  button {
    transform: scale(0.8);
  }

  #middle-button {
    margin-inline: 0px;
  }
}
@media (max-width: 400px) {
  .vehicle-table {
    width: 50vw;
  }
}
</style>
