<template>
  <div id="liveMapPage">
    <div class="spinner-container-big" v-if="this.load">
      <radar-spinner
        :animation-duration="2000"
        :size="100"
        color="#dd9c44"
        id="big-spinner"
      />

      <h1 class="loading-title">{{ $t("language.loadingTitle") }}</h1>
    </div>
    <!-- <button
      class="btn btn-secondary"
      id="measure-btn"
      data-bs-toggle="tooltip"
      data-bs-placement="left"
      data-bs-title="This top tooltip is themed via CSS variables."
      @click="this.changeMeasure()"
    >
      <i class="fa-solid fa-ruler"></i>
    </button> -->
    <div
      class="offcanvas offcanvas-end"
      style="background-color: #202528; color: white"
      tabindex="-1"
      id="offcanvasRight"
      aria-labelledby="offcanvasRightLabel"
    >
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="replay-label">
          {{ $t("language.replayInfo") }}
        </h5>
        <button
          type="button"
          class="btn-close btn-close-white"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div class="offcanvas-body" style="margin: 20px 16%; padding-bottom: 35%">
        {{ $t("language.replayTitle") }}
        <br /><br />
        {{ $t("language.replayText") }}
        <br />
        <button
          class="btn btn-secondary"
          disabled
          style="background-color: black; width: 45px"
        >
          <i class="fa-solid fa-info"></i>
        </button>
        <br /><br /><br />
        {{ $t("language.replayEvent") }} <br /><br />
        {{ $t("language.firstRep") }}
        <br />
        {{ $t("language.secondRep") }} <br />
        <button
          class="btn btn-secondary"
          disabled
          style="background-color: orange; width: 45px"
          type="button"
        >
          <i class="fa-solid fa-play"></i>
        </button>
        <br /><br /><br />
        {{ $t("language.toCheck") }}
        <br /><br />
        {{ $t("language.selectPassed1") }} <br />
        <button
          class="btn btn-secondary"
          disabled
          style="background-color: black; width: 45px"
        >
          <i class="fa-solid fa-road"></i>
        </button>
        <br />
        {{ $t("language.selectPassed2") }}<br />
        <button
          style="
            background-color: black;
            width: 30px;
            border-radius: 50px;
            border-width: 1%;
          "
          disabled
        >
          <i style="color: white" class="fa-solid fa-play"></i>
        </button>
        <br />
        {{ $t("language.selectPassed3") }} <br /><br /><br />
        {{ $t("language.racerPos") }}
        <br /><br />
        {{ $t("language.racerPos1") }} <br />
        <button
          disabled
          class="btn btn-secondary"
          style="background-color: black; width: 45px"
        >
          <i class="fa-solid fa-timeline"></i>
        </button>
        <br />
        {{ $t("language.racerPos2") }}<br />
        <button
          disabled
          style="
            background-color: black;
            width: 30px;
            border-radius: 50px;
            border-width: 1%;
          "
        >
          <i style="color: white" class="fa-solid fa-play"></i>
        </button>
        <br />
        {{ $t("language.racerPos3") }} <br />
        {{ $t("language.racerPos4") }} <br />
        <button
          disabled
          class="btn btn-secondary"
          style="
            background-color: orange;
            width: 55px;
            display: flex;
            margin: auto;
          "
          type="button"
        >
          <i class="fa-solid fa-play"></i><i class="fa-solid fa-timeline"></i>
        </button>
      </div>
    </div>
    <div
      class="alert alert-light"
      id="total-distance"
      role="alert"
      v-if="this.measure"
    >
      {{ $t("language.totDis") }}&nbsp; {{ this.totalDistanceString }}
    </div>
    <div class="sidebar" id="side-bar">
      <div class="sidebarBtns" id="blackCircleButtons">
        <BlackCircleButton
          id="bc9"
          :blackCircleImage="homeimg"
          style="margin-bottom: 7vh; margin-top: 2vh"
          @blackCircleClick="
            navigateHomPage();
            this.isMapSetting = false;
          "
        />
        <div
          class="button-margin"
          style="
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            height: 100%;
          "
        >
          <BlackCircleButton
            id="bc1"
            :blackCircleImage="replayImg"
            @blackCircleClick="
              this.checkPopupId(4);
              this.isMapSetting = false;
            "
            :imageColor="{ color: popUpId == 4 ? '#DD9C44' : '' }"
          />
          <BlackCircleButton
            id="bc3"
            :blackCircleImage="stagesImg"
            @blackCircleClick="
              this.checkPopupId(2);
              this.isMapSetting = false;
              this.isOpen = !this.isOpen;
            "
            :imageColor="{ color: popUpId == 2 ? '#DD9C44' : '' }"
            v-if="
              this.role == 'admin' ||
              this.role == 'staff' ||
              this.role == 'clerk'
            "
          />
          <BlackCircleButton
            id="bc4"
            :blackCircleImage="poiImg"
            @blackCircleClick="
              this.checkPopupId(3);
              this.isMapSetting = false;
            "
            :imageColor="{ color: popUpId == 3 ? '#DD9C44' : '' }"
          />
          <div class="mapOptionFlexBox">
            <BlackCircleButton
              id="bc5"
              :blackCircleImage="settingsImg"
              @blackCircleClick="
                this.isMapSetting = !this.isMapSetting;
                this.checkPopupId(6);
              "
              :imageColor="{
                color: popUpId == 6 ? '#DD9C44' : '',
              }"
            />
            <div v-show="this.isMapSetting === true" class="mapOption-popup">
              <div class="mapOption-popup-content">
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-around;
                  "
                >
                  <div
                    @click="changeLayer(0), (this.switchLayer = 0)"
                    style="position: relative; cursor: pointer"
                  >
                    <img
                      class="backgroundOptionImg"
                      src="/lightmode_background.png"
                      alt="Light Background"
                      :style="{
                        border:
                          this.switchLayer == 0 ? '#dd9c44  solid 1px' : '',
                      }"
                    />
                    <label
                      style="
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        color: black;
                        padding: 5px;
                        margin-bottom: 10px;
                      "
                      >{{ $t("language.defMode") }}</label
                    >
                  </div>
                  <div
                    @click="changeLayer(1), (this.switchLayer = 1)"
                    style="position: relative; cursor: pointer"
                  >
                    <img
                      class="backgroundOptionImg"
                      src="/darkmode_background.png"
                      alt="Dark Background"
                      :style="{
                        border:
                          this.switchLayer == 1 ? '#dd9c44  solid 1px' : '',
                      }"
                    />
                    <label
                      style="
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        color: white;
                        padding: 5px;
                        margin-bottom: 10px;
                      "
                      >{{ $t("language.darkMode") }}</label
                    >
                  </div>
                  <div
                    @click="changeLayer(2), (this.switchLayer = 2)"
                    style="position: relative; cursor: pointer"
                  >
                    <img
                      class="backgroundOptionImg"
                      src="/satellite_background.png"
                      alt="Satellite Background"
                      :style="{
                        border:
                          this.switchLayer == 2 ? '#dd9c44  solid 1px' : '',
                      }"
                    />
                    <label
                      style="
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        color: white;
                        padding: 5px;
                        margin-bottom: 10px;
                      "
                      >{{ $t("language.satView") }}</label
                    >
                  </div>
                </div>
                <div
                  style="
                    background-color: #202528;
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                    margin-top: 0;
                    padding-top: 2%;
                    padding-bottom: 2%;
                  "
                >
                  <div
                    id="iconSizer"
                    style="font-weight: bolder; color: white; margin: auto"
                    v-show="false"
                  >
                    {{ $t("language.racerIcon") }}
                    <button
                      class="smallBtn"
                      id="iconSizerBtn"
                      @click="smallIcons()"
                      style="margin-right: 2%"
                    >
                      {{ $t("language.smText") }}
                    </button>
                    <button
                      class="largeBtn"
                      id="iconSizerBtn"
                      @click="bigIcons()"
                    >
                      {{ $t("language.lgText") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style="
            position: fixed;
            bottom: 0;
            left: auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
          "
        ></div>
      </div>
    </div>
    <BlackCircleButton
      v-show="!displaySidebar"
      id="bc1"
      class="burgerOpenButton"
      style="z-index: 1100; margin-right: 1vw; margin-left: 0.7vw"
      :blackCircleImage="burgerImg"
      @blackCircleClick="this.displaySidebar = true"
      :imageColor="{ color: popUpId == 1 ? '#DD9C44' : '' }"
      v-if="
        this.role == 'admin' || this.role == 'staff' || this.role == 'clerk'
      "
    />
    <div v-show="displaySidebar" class="sidebarBurger" id="side-bar">
      <div class="sidebarBtns" id="blackCircleButtons">
        <BlackCircleButton
          id="bc1"
          :blackCircleImage="burgerImg"
          style="margin-bottom: 5vh"
          @blackCircleClick="this.displaySidebar = false"
          :imageColor="{ color: popUpId == 1 ? '#DD9C44' : '' }"
          v-if="
            this.role == 'admin' || this.role == 'staff' || this.role == 'clerk'
          "
        />

        <div
          class="button-margin"
          style="
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            height: 100%;
          "
        >
          <BlackCircleButton
            id="bc9"
            :blackCircleImage="homeimg"
            style=""
            @blackCircleClick="
              navigateHomPage();
              this.isMapSetting = false;
            "
          />
          <BlackCircleButton
            id="bc1"
            :blackCircleImage="replayImg"
            @blackCircleClick="
              this.checkPopupId(4);
              this.isMapSetting = false;
            "
            :imageColor="{ color: popUpId == 4 ? '#DD9C44' : '' }"
          />
          <BlackCircleButton
            id="bc3"
            :blackCircleImage="stagesImg"
            @blackCircleClick="
              this.checkPopupId(2);
              this.isMapSetting = false;
              this.isOpen = !this.isOpen;
            "
            :imageColor="{ color: popUpId == 2 ? '#DD9C44' : '' }"
            v-if="
              this.role == 'admin' ||
              this.role == 'staff' ||
              this.role == 'clerk'
            "
          />
          <BlackCircleButton
            id="bc4"
            :blackCircleImage="poiImg"
            @blackCircleClick="
              this.checkPopupId(3);
              this.isMapSetting = false;
            "
            :imageColor="{ color: popUpId == 3 ? '#DD9C44' : '' }"
          />
          <div class="mapOptionFlexBox">
            <BlackCircleButton
              id="bc5"
              :blackCircleImage="settingsImg"
              @blackCircleClick="
                this.isMapSetting = !this.isMapSetting;
                this.checkPopupId(6);
              "
              :imageColor="{
                color: popUpId == 6 ? '#DD9C44' : '',
              }"
            />
            <div v-show="this.isMapSetting === true" class="mapOption-popup">
              <div class="mapOption-popup-content">
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-around;
                  "
                >
                  <div
                    @click="changeLayer(0), (this.switchLayer = 0)"
                    style="position: relative; cursor: pointer"
                  >
                    <img
                      class="backgroundOptionImg"
                      src="/lightmode_background.png"
                      alt="Light Background"
                      :style="{
                        border:
                          this.switchLayer == 0 ? '#dd9c44  solid 1px' : '',
                      }"
                    />
                    <label
                      style="
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        color: black;
                        padding: 5px;
                        margin-bottom: 10px;
                      "
                      >{{ $t("language.defMode") }}</label
                    >
                  </div>
                  <div
                    @click="changeLayer(1), (this.switchLayer = 1)"
                    style="position: relative; cursor: pointer"
                  >
                    <img
                      class="backgroundOptionImg"
                      src="/darkmode_background.png"
                      alt="Dark Background"
                      :style="{
                        border:
                          this.switchLayer == 1 ? '#dd9c44  solid 1px' : '',
                      }"
                    />
                    <label
                      style="
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        color: white;
                        padding: 5px;
                        margin-bottom: 10px;
                      "
                      >{{ $t("language.darkMode") }}</label
                    >
                  </div>
                  <div
                    @click="changeLayer(2), (this.switchLayer = 2)"
                    style="position: relative; cursor: pointer"
                  >
                    <img
                      class="backgroundOptionImg"
                      src="/satellite_background.png"
                      alt="Satellite Background"
                      :style="{
                        border:
                          this.switchLayer == 2 ? '#dd9c44  solid 1px' : '',
                      }"
                    />
                    <label
                      style="
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        color: white;
                        padding: 5px;
                        margin-bottom: 10px;
                      "
                      >{{ $t("language.satView") }}</label
                    >
                  </div>
                </div>
                <div
                  style="
                    background-color: #202528;
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                    margin-top: 0;
                    padding-top: 2%;
                    padding-bottom: 2%;
                  "
                >
                  <div
                    id="iconSizer"
                    style="font-weight: bolder; color: white; margin: auto"
                    v-show="false"
                  >
                    {{ $t("language.racerIcon") }}
                    <button
                      class="smallBtn"
                      id="iconSizerBtn"
                      @click="smallIcons()"
                      style="margin-right: 2%"
                    >
                      {{ $t("language.smText") }}
                    </button>
                    <button
                      class="largeBtn"
                      id="iconSizerBtn"
                      @click="bigIcons()"
                    >
                      {{ $t("language.lgText") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style="
            position: fixed;
            bottom: 0;
            left: auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
          "
        ></div>
      </div>
    </div>
    <div v-show="popUpId === 1" class="data-popup">
      <div style="display: flex; flex-direction: column">
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-inline: 1vw;
            padding-top: 1vh;
            padding-bottom: 2vh;
          "
        >
          <h1>Logs</h1>
          <button @click="closePopup" class="back-button">
            <i class="fa-solid fa-arrow-left fa-2xl"></i>
          </button>
        </div>
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-inline: 1vw;
            padding-bottom: 2vh;
          "
        >
          <h2>Signaling logs</h2>
        </div>
      </div>
      <div sm="12" md="6" class="center-alignment">
        <div class="outer-table">
          <table class="custom-table">
            <thead>
              <tr>
                <th style="width: 18%" colspan="4">Start number</th>
                <th style="width: 15%" colspan="4">Status</th>
                <th style="width: 20%" colspan="4">Recorded at</th>
                <th style="width: 15%" colspan="4">Stage</th>
                <th style="width: 20%" colspan="4">Type</th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>
    <div v-show="popUpId === 2" class="data-popup">
      <div style="display: flex; flex-direction: column">
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding-inline: 1vw;
            padding-top: 1vh;
            padding-bottom: 2vh;
          "
        >
          <span class="alert-text">{{ $t("language.stagesLabel") }}</span>

          <div
            style="display: flex; justify-content: center; align-items: center"
          >
            <button
              class="btn btn-secondary"
              id="checkInfo-btn"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasStages"
              aria-controls="offcanvasStages"
              data-bs-title="This top tooltip is themed via CSS variables."
            >
              <i class="fa-solid fa-info"></i>
            </button>
            <button @click="closePopup" class="back-button">
              <i class="fa-solid fa-arrow-left fa-2xl"></i>
            </button>
          </div>
        </div>
        <div
          class="offcanvas offcanvas-end"
          tabindex="-1"
          id="offcanvasStages"
          style="background-color: #202528; color: white"
          aria-labelledby="offcanvasStagesLabel"
        >
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasRightLabel">
              Stages Info
            </h5>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div class="offcanvas-body">
            {{ $t("language.welcomeText") }}<br />
            <br />
            {{ $t("language.closeText") }}
            <br />
            <button
              disabled
              class="btn btn-secondary"
              style="
                background-color: orange;
                width: 35px;
                height: 35px;
                border-radius: 50%;
                color: black;
              "
            >
              <i class="fa-solid fa-info"></i>
            </button>
            <br /><br /><br />
            {{ $t("language.singleStageText") }}
            <br />
            <br />
            {{ $t("language.stageSelect") }}
            <br />
            <button
              disabled
              class="btn btn-secondary"
              style="background-color: grey; width: 45px"
              type="button"
            >
              <i class="fa-solid fa-crosshairs"></i>
            </button>
            <br /><br /><br />
          </div>
        </div>
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-inline: 1vw;
            padding-bottom: 2vh;
          "
        >
          <!-- <button class="filter-button">
            <i style="margin-right: 3px" class="fa-solid fa-filter"></i>
            Filter
          </button> -->
        </div>
      </div>
      <div sm="12" md="6" class="center-alignment" v-if="this.role">
        <div class="outer-table">
          <table class="custom-table">
            <thead>
              <tr>
                <th style="width: 8%" colspan="5"></th>
                <th style="width: 15%" colspan="5">
                  {{ $t("language.stageName") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) of this.stages"
                :key="item.stage_id"
                :id="'table-tr' + item.stage_id"
              >
                <td colspan="5" style="text-align: left">
                  <button
                    class="btn btn-secondary cross"
                    id="crosshairButton"
                    style="
                      --bs-btn-padding-y: 0.25rem;
                      --bs-btn-padding-x: 0.5rem;
                      --bs-btn-font-size: 0.8rem;
                    "
                    @click="
                      setMapViewButtonClick(index, 'table-tr' + item.stage_id)
                    "
                  >
                    <i class="fa-solid fa-crosshairs"></i>
                  </button>
                </td>
                <td colspan="5" style="text-align: left">
                  {{ item.stage_name }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div v-show="popUpId === 3" class="data-popup" style="width: 25%">
      <div style="display: flex; flex-direction: column; overflow-y: auto">
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding-inline: 1vw;
            padding-top: 1vh;
            padding-bottom: 2vh;
          "
        >
          <span class="alert-text">{{ $t("language.poiSettings") }}</span>
          <div
            style="display: flex; justify-content: center; align-items: center"
          >
            <div>
              <button
                class="btn btn-primary"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasPoi"
                aria-controls="offcanvasPoi"
                id="checkInfo-btn"
                style="--bs-btn-padding-x: 0.5rem; --bs-btn-font-size: 0.8rem"
              >
                <i class="fa-solid fa-info"></i>
              </button>
            </div>
            <button @click="closePopup" class="back-button">
              <i class="fa-solid fa-arrow-left fa-2xl"></i>
            </button>
          </div>
        </div>
        <div
          class="offcanvas offcanvas-end"
          style="background-color: #202528; color: white"
          tabindex="-1"
          id="offcanvasPoi"
          aria-labelledby="offcanvasPoiLabel"
          @hook:updated="checkOffcanvasState"
        >
          <div class="offcanvas-header">
            <h5 id="offcanvasPoiLabel">POI Info</h5>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div class="offcanvas-body">
            {{ $t("language.poiTitle") }}
            <br />
            {{ $t("language.poiClose") }}
            <br />
            <br />
            <button
              disabled
              class="btn btn-secondary"
              style="
                background-color: orange;
                width: 35px;
                height: 35px;
                border-radius: 50%;
                color: black;
              "
            >
              <i class="fa-solid fa-info"></i>
            </button>
            <br /><br /><br />
            <br />
            {{ $t("language.poiView") }}
            <br />
            <br />

            <!-- <img :src="tooltipPoi" alt="poi tooltip" style="width: 15vw" /> -->

            <br /><br /><br />
            {{ $t("language.poiLimit") }}
            <br />
            <br />
            <!-- <img :src="checkboxPoi" alt="poi tooltip" style="width: 12vw" />
            <br /><br /><br /> -->
            {{ $t("language.poiTypes") }}
            <br />
            <br />
            <div style="display: flex; flex-direction: column">
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <img
                  src="icons/finish.png"
                  class="poi-img"
                  alt="finish icon"
                  style="width: 5vw"
                />
                <span style="text-align: left">{{
                  $t("language.finish_POI")
                }}</span>
                <br />
              </div>
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <img
                  src="icons/ss-start.png"
                  class="poi-img"
                  alt="start icon"
                  style="width: 5vw"
                />
                <span style="text-align: left">{{
                  $t("language.Stage_start")
                }}</span>
                <br />
              </div>
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <img
                  src="icons/ss-stop.png"
                  class="poi-img"
                  alt="finish icon"
                  style="width: 5vw"
                />
                <span style="text-align: left">{{
                  $t("language.Stage_Stop")
                }}</span>
                <br />
              </div>
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <img
                  src="icons/firetruck.png"
                  class="poi-img"
                  alt="finish icon"
                  style="width: 5vw"
                />
                <span style="text-align: left">{{
                  $t("language.Firetruck")
                }}</span>
                <br />
              </div>
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <img
                  src="icons/hlz.png"
                  class="poi-img"
                  alt="finish icon"
                  style="width: 5vw"
                />
                <span style="text-align: left">{{
                  $t("language.Helicopter_Landing_Zone")
                }}</span>
                <br />
              </div>
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <img
                  src="icons/marshall.png"
                  class="poi-img"
                  alt="finish icon"
                  style="width: 5vw"
                />
                <span style="text-align: left">{{
                  $t("language.Marshall")
                }}</span>
                <br />
              </div>
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <img
                  src="icons/medical.png"
                  class="poi-img"
                  alt="finish icon"
                  style="width: 5vw"
                />
                <span style="text-align: left">{{
                  $t("language.Medical")
                }}</span>
                <br />
              </div>
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <img
                  src="icons/waypoint.png"
                  class="poi-img"
                  alt="finish icon"
                  style="width: 5vw"
                />
                <span style="text-align: left">{{
                  $t("language.Waypoint")
                }}</span>
                <br />
              </div>
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <img
                  src="icons/radio.png"
                  class="poi-img"
                  alt="finish icon"
                  style="width: 5vw"
                />
                &emsp;
                <span style="text-align: center">{{
                  $t("language.Radio")
                }}</span>
                <br />
              </div>
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <img
                  src="icons/rnep.png"
                  class="poi-img"
                  alt="finish icon"
                  style="width: 5vw"
                />
                <span style="text-align: left">{{
                  $t("language.Route_Note_Exchange_Point")
                }}</span>
                <br />
              </div>
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <img
                  src="icons/rz.png"
                  class="poi-img"
                  alt="finish icon"
                  style="width: 5vw"
                />
                <span style="text-align: left">{{
                  $t("language.Refuel_Zone")
                }}</span>
                <br />
              </div>
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <img
                  src="icons/service.png"
                  class="poi-img"
                  alt="finish icon"
                  style="width: 5vw"
                />
                <span style="text-align: left">{{
                  $t("language.Service")
                }}</span>
                <br />
              </div>
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <img
                  src="icons/sz.png"
                  class="poi-img"
                  alt="finish icon"
                  style="width: 5vw"
                />
                <span style="text-align: left">{{
                  $t("language.Spectator_Zone")
                }}</span>
                <br />
              </div>
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <img
                  src="icons/tc.png"
                  class="poi-img"
                  alt="finish icon"
                  style="width: 5vw"
                />
                <span style="text-align: left">{{
                  $t("language.Time_Control")
                }}</span>
                <br />
              </div>
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <img
                  src="icons/tf.png"
                  class="poi-img"
                  alt="finish icon"
                  style="width: 5vw"
                />
                <span style="text-align: left">{{
                  $t("language.Tire_Fitting")
                }}</span>
                <br />
              </div>
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <img
                  src="icons/tm.png"
                  class="poi-img"
                  alt="finish icon"
                  style="width: 5vw"
                />
                <span style="text-align: left">{{
                  $t("language.Tire_Marking")
                }}</span>
                <br />
              </div>
              <div
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <img
                  src="icons/mp.png"
                  class="poi-img"
                  alt="finish icon"
                  style="width: 5vw"
                />
                <span style="text-align: left">{{
                  $t("language.Media_Point")
                }}</span>
                <br />
              </div>
            </div>
          </div>
        </div>
        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-inline: 1vw;
            padding-bottom: 2vh;
            height: 80%;
            overflow-x: auto;
          "
          class="poi-filter-container"
        >
          <br />
          <div class="poi-filter-container">
            <div
              class="form-check checkboxRow"
              v-for="item in this.poiTypes"
              v-bind:key="item"
            >
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
                style="margin-bottom: 10px"
                checked="true"
                @click="this.poiFilter(item)"
              />
              <img :src="'icons/' + item + '.png'" class="poi-filter-icon" />
              <label
                class="form-check-label"
                for="flexCheckDefault"
                id="poi-text"
              >
                &nbsp; {{ item }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="popUpId === 4" class="data-popup">
      <div style="display: flex; flex-direction: column">
        <div
          style="
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding-inline: 1vw;
            padding-top: 1vh;
            padding-bottom: 2vh;
          "
        >
          <h1 class="replay-title">{{ $t("language.selectRaceReplay") }}</h1>
          <button
            class="btn btn-secondary"
            id="CheckInfoData-btn"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasRight"
            aria-controls="offcanvasRight"
            data-bs-title="This top tooltip is themed via CSS variables."
          >
            <i class="fa-solid fa-info"></i>
          </button>
          <button @click="closePopup" class="back-button">
            <i class="fa-solid fa-arrow-left fa-2xl"></i>
          </button>
        </div>
        <div class="functionBtns">
          <div class="left-search">
            <input
              class="search-bar2"
              v-model="searchQuery"
              placeholder="Search..."
            />
            <i class="search-icon2" :class="searchImage"></i>
          </div>
          <div class="right-btns">
            <input
              type="number"
              min="15"
              max="150"
              id="RadiusField"
              v-if="this.CheckSectionDataBool"
              v-model="this.radius"
            />
            <button
              class="btn btn-secondary"
              id="CheckSectionData-btn"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              data-bs-title="This top tooltip is themed via CSS variables."
              @click="this.CheckSectionData()"
            >
              <i class="fa-solid fa-road"></i>
            </button>
            <button
              class="btn btn-secondary"
              id="CheckTripData-btn"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              data-bs-title="This top tooltip is themed via CSS variables."
              @click="this.CheckTripData()"
            >
              <i class="fa-solid fa-timeline"></i>
            </button>
            <button
              id="trailBtn"
              class="btn btn-secondary"
              @click="
                trailRacers();
                CheckTrailData();
              "
            >
              <i class="fa-solid fa-wave-square"></i>
            </button>
          </div>
        </div>
      </div>
      <div sm="12" md="6" class="center-alignment">
        <div class="outer-table">
          <table class="custom-table">
            <thead>
              <tr>
                <th style="width: 1%" colspan="3">
                  <!-- {{ $t("language.showBool") }} -->
                </th>
                <th style="width: 4%" colspan="3">
                  {{ $t("language.startnum") }}
                </th>
                <th style="width: 10%" colspan="3">
                  {{ $t("language.racerName") }}
                </th>
                <th
                  style="width: 5%; text-align: center !important"
                  colspan="3"
                >
                  <input
                    v-if="this.isPlaying"
                    class="form-check-input"
                    type="checkbox"
                    v-model="isChecked"
                    disabled
                  />
                  <input
                    v-else
                    class="form-check-input"
                    type="checkbox"
                    v-model="isChecked"
                  />
                </th>
                <th
                  v-if="this.CheckTripDataBool"
                  style="width: 10%"
                  colspan="3"
                >
                  {{ $t("language.trailRacers") }}
                </th>
                <th
                  v-if="this.CheckSectionDataBool"
                  style="width: 10%"
                  colspan="3"
                >
                  {{ $t("language.trailRacers2") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in this.filteredReplayRacers" :key="item.id">
                <td>
                  <button
                    class="btn btn-primary cross"
                    @click="showReplayRacer(item)"
                  >
                    <i
                      class="fa-solid fa-crosshairs"
                      style="margin-left: 35%"
                    ></i>
                  </button>
                </td>
                <td
                  colspan="3"
                  id="racerNumberReplay"
                  style="text-align: left !important"
                >
                  {{ item.racer_start_num }}
                </td>
                <td v-if="item.racer_pilot_name" colspan="3">
                  {{ item.racer_pilot_name }}
                </td>
                <td v-else colspan="3">-</td>
                <td colspan="3" style="text-align: center !important">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    :id="'racerChk' + item.racer_id"
                    @change="selectRacer($event, item.racer_id)"
                    v-if="this.replayRacerArray.includes(item.racer_id)"
                    checked
                  />
                  <input
                    type="checkbox"
                    class="form-check-input"
                    :id="'racerChk' + item.racer_id"
                    @change="selectRacer($event, item.racer_id)"
                    v-if="!this.replayRacerArray.includes(item.racer_id)"
                  />
                </td>
                <td colspan="3" v-show="this.CheckTripDataBool">
                  <input
                    class="custom-time-input"
                    :id="'racerInputChk' + item.racer_id"
                    type="time"
                    disabled
                    @change="changeTime($event, item)"
                  />
                </td>
                <td
                  colspan="3"
                  v-if="
                    this.CheckSectionDataBool &&
                    this.replayRacerArray.includes(item.racer_id)
                  "
                >
                  {{ getPositionRecordedAt(item.racer_id).join("\n") }}
                </td>
                <td colspan="3" v-else-if="this.CheckSectionDataBool"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="right-content" id="search-field">
      <input
        class="search-bar"
        id="racerSearchBar"
        v-model="searchValue"
        :placeholder="SearchRacerText"
        @click="activateSearch"
      />
      <i
        class="search-icon"
        style="font-size: 1.2em; color: white"
        :class="searchImage"
      ></i>
      <div class="search-result-container" v-if="this.isSearch">
        <table class="table" id="result-table">
          <tbody>
            <tr
              class="search-result-tr"
              v-for="item in this.filteredRacers"
              :key="item.racer_id"
              @click="this.showRacer(item)"
            >
              <td class="num-td">
                <p>{{ item.racer_start_num }}</p>
              </td>
              <td class="name-td">
                <p class="result-name">{{ item.racer_pilot_name }}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="selected-racer-modal" v-if="this.isSelected">
    <div class="racer-title-row" v-if="this.selectedRacer">
      <h1 class="racer-title">
        {{ this.selectedRacer.racer_start_num }}.
        {{ this.selectedRacer.racer_pilot_name }}
      </h1>
      <button class="btn btn-primary" id="close-info" @click="closeRacer()">
        <i class="fa-solid fa-x"></i>
      </button>
    </div>
    <div class="actual-racer-table">
      <table class="table">
        <thead>
          <tr>
            <th scope="col" class="racer-data">Status</th>
            <th scope="col" class="racer-data">Nationality</th>
            <th scope="col" class="racer-data">Co-Pilot</th>
            <th scope="col" class="racer-data">Device ID</th>
            <th scope="col" class="racer-data">Beérkezett</th>
            <th scope="col" class="racer-data">Sebesség</th>
            <th scope="col" class="racer-data">G-erő</th>
          </tr>
        </thead>
        <tbody v-if="this.selectedRacer">
          <tr>
            <td class="racer-data">{{ this.selectedRacer.racer_status }}</td>
            <td class="racer-data">
              {{ this.selectedRacer.racer_pilot_nationality }}
            </td>
            <td class="racer-data">
              {{ this.selectedRacer.racer_copilot_name }}
            </td>
            <td class="racer-data">{{ this.selectedRacer.device_id }}</td>
            <td class="racer-data">
              {{ formatTimeDate(this.actualLastData[0].received_at) }}
            </td>
            <td class="racer-data">
              {{ this.actualLastData[0].velocity }} km/h
            </td>
            <td class="racer-data">{{ this.actualLastData[0].g_force }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="spinner-container" v-if="this.loading">
      <radar-spinner
        :animation-duration="2000"
        :size="80"
        color="#dd9c44"
        id="spinner"
      />
    </div>
    <div class="select-container">
      <label
        for="chart-select"
        class="col-form-label"
        id="chart-select-label"
        >{{ $t("language.raceriLengthChart") }}</label
      >
      <select v-model="this.selectedNumber" id="chart-select">
        <option value="10">10</option>
        <option value="20">20</option>
        <option value="50">50</option>
        <option value="100">100</option>
        <option value="200">200</option>
      </select>
    </div>
    <div class="chartDiv1">
      <canvas ref="lineChart" id="chart1"></canvas>
    </div>
    <div class="chartDiv2">
      <canvas ref="lineChart2" id="chart2"></canvas>
    </div>
    <!-- <br />
    <button class="btn btn-primary" id="more-info-button" @click="navToRacer()">
      <i class="fa-solid fa-circle-info"></i> More info
    </button> -->
  </div>
  <div id="mapContainer"></div>

  <!-- ------------------------REPLAY------------------------------- -->
  <div v-show="this.isMapSetting === false" class="replayDiv">
    <div class="slidecontainer">
      <button
        class="btn btn-secondary replaySettingsBtn"
        id="replayMiddleBtn"
        type="button"
        v-if="this.isPlaying == false && this.CheckTripDataBool"
        @click="
          this.isPlaying = true;
          startMultiTimeReplay();
        "
      >
        <i class="fa-solid fa-play"></i>&nbsp;
        <i class="fa-solid fa-timeline"></i>
      </button>
      <button
        class="btn btn-secondary replaySettingsBtn"
        type="button"
        v-else-if="this.isPlaying == false"
        @click="
          this.isPlaying = true;
          startReplay();
        "
      >
        <i class="fa-solid fa-play"></i>
      </button>
      <button
        class="btn btn-secondary replaySettingsBtn"
        type="button"
        v-else
        @click="
          this.isPlaying = false;
          stopReplay();
        "
      >
        <i class="fa-solid fa-pause"></i>
      </button>

      <input v-if="this.CheckTripDataBool" type="range" hidden />

      <input
        v-else
        type="range"
        :min="new Date(this.firstAndLast.firstDate).getTime()"
        :max="new Date(this.firstAndLast.lastDate).getTime()"
        v-model="this.timestamp"
        @change="handleTimeSliderChange()"
        class="form-range custom-range"
        id="timeSlider"
      />
    </div>

    <div
      v-if="this.CheckTripDataBool"
      class="replayButtonRow col-md-12 text-center"
    ></div>
    <div
      v-else-if="!this.isPlaying"
      class="replayButtonRow col-md-12 text-center"
    >
      <button
        class="btn btn-secondary replaySettingsBtn"
        type="button"
        :disabled="
          new Date(parseInt(this.timestamp)).getTime() <=
          new Date(this.firstAndLast.firstDate).getTime()
        "
        @click="decreaseTimestamp(300000)"
      >
        -5 mins
      </button>
      <button
        class="btn btn-secondary replaySettingsBtn"
        type="button"
        :disabled="
          new Date(parseInt(this.timestamp)).getTime() <=
          new Date(this.firstAndLast.firstDate).getTime()
        "
        @click="decreaseTimestamp(30000)"
      >
        -30 sec
      </button>
      <button
        class="btn btn-secondary replaySettingsBtn"
        type="button"
        :disabled="
          new Date(parseInt(this.timestamp)).getTime() <=
          new Date(this.firstAndLast.firstDate).getTime()
        "
        @click="decreaseTimestamp(10000)"
      >
        -10 sec
      </button>

      <p style="margin-left: 15px; margin-right: 15px; margin-top: 0.5%">
        {{ new Date(parseInt(this.timestamp)).toLocaleString() }}
      </p>

      <button
        class="btn btn-secondary replaySettingsBtn"
        type="button"
        :disabled="
          new Date(parseInt(this.timestamp)).getTime() >=
          new Date(this.firstAndLast.lastDate).getTime()
        "
        @click="increaseTimestamp(10000)"
      >
        +10 sec
      </button>
      <button
        class="btn btn-secondary replaySettingsBtn"
        type="button"
        :disabled="
          new Date(parseInt(this.timestamp)).getTime() >=
          new Date(this.firstAndLast.lastDate).getTime()
        "
        @click="increaseTimestamp(30000)"
      >
        +30 sec
      </button>
      <button
        class="btn btn-secondary replaySettingsBtn"
        type="button"
        :disabled="
          new Date(parseInt(this.timestamp)).getTime() >=
          new Date(this.firstAndLast.lastDate).getTime()
        "
        @click="increaseTimestamp(300000)"
      >
        +5 mins
      </button>
    </div>
    <div v-else class="replayButtonRow col-md-12 text-center">
      <button
        class="btn btn-secondary replaySettingsBtn"
        type="button"
        disabled
      >
        -5 mins
      </button>
      <button
        class="btn btn-secondary replaySettingsBtn"
        type="button"
        disabled
      >
        -30 sec
      </button>
      <button
        class="btn btn-secondary replaySettingsBtn"
        type="button"
        disabled
      >
        -10 sec
      </button>

      <p style="margin-left: 15px; margin-right: 15px; margin-top: 0.5%">
        {{ new Date(parseInt(this.timestamp)).toLocaleString() }}
      </p>

      <button
        class="btn btn-secondary replaySettingsBtn"
        type="button"
        disabled
      >
        +10 sec
      </button>
      <button
        class="btn btn-secondary replaySettingsBtn"
        type="button"
        disabled
      >
        +30 sec
      </button>
      <button
        class="btn btn-secondary replaySettingsBtn"
        type="button"
        disabled
      >
        +5 mins
      </button>
    </div>
  </div>
  <CustomAlert
    :show="showAlert"
    :body="errorMessage"
    @confirm="showAlert = false"
    :showCancelButton="false"
  />
</template>

<script>
import L from "leaflet";
import { LMap, LTileLayer, LControlZoom } from "leaflet";
import BlackCircleButton from "./BlackCircleButton.vue";
import CustomAlert from "./CustomAlert.vue";

import dataProvider from "../utils/dataProvider";
const dp = dataProvider();
const range = process.env.VUE_APP_SNAPPING_RANGE || 15;
import "leaflet/dist/leaflet.css";
import Chart from "chart.js/auto";
import { RadarSpinner } from "epic-spinners";
import mqtt from "mqtt/dist/mqtt";
import "leaflet-rotatedmarker";
import jwt_decode from "jwt-decode";
const { DateTime } = require("luxon");

const replay_topic = process.env.VUE_APP_REPLAY_TOPIC || "replay";
const topic_prefix = process.env.VUE_APP_TOPIC_PREFIX || ""; //demo/

export default {
  name: "Map",
  L,
  LMap,
  LTileLayer,
  LControlZoom,
  data() {
    return {
      selectedNumber: 10,
      alertImage:
        "fa-solid fa-triangle-exclamation" /* fa-triangle-exclamation */,
      searchValue: "",
      searchImage: "fa-solid fa-magnifying-glass",
      logsImg: "fa-solid fa-list",
      burgerImg: "fa-solid fa-bars",
      stagesImg: "fa-solid fa-route",
      poiImg: "fa-solid fa-location-dot",
      projectModeImg: "fa-solid fa-table-cells-large",
      replayImg: "fa-solid fa-play",
      settingsImg: "fa-solid fa-gears",
      homeimg: "fa-solid fa-arrow-left",
      center: [47.6875, 17.6504],
      mapDiv: undefined,
      baseLayer: undefined,
      switchLayer: 0,
      isDropdownVisible: false,
      legName: undefined,
      legDate: undefined,
      popUpId: 0,
      stages: [],
      pois: [],
      markers: [],
      stageCoords: [],
      loading: true,
      poiTypes: [],
      poiTypeCounter: 0,
      newCenter: undefined,
      polyline: undefined,
      stageLogs: [],
      stageColor: [],
      usersArray: [],
      markerElements: [],
      isMapSetting: false,
      ssstartShow: true,
      ssstopShow: true,
      finishShow: true,
      tmShow: true,
      tfShow: true,
      tcShow: true,
      szShow: true,
      serviceShow: true,
      rzShow: true,
      rnepShow: true,
      radioShow: true,
      marshallShow: true,
      hlzShow: true,
      firetruckShow: true,
      medicalShow: true,
      waypointShow: true,
      legId: undefined,
      legMap: [],
      lastDeviceArray: [],
      carMarkerArray: [],
      carMarkerArray2: [],
      measureMarker: undefined,
      measureMarkers: [],
      measure: false,
      measureLines: [],
      totalDistance: 0,
      totalDistanceString: "0 m",
      distance: 0,
      distanceArray: [],
      dwell_time: undefined,
      uniqueDistances: [],
      highlightBorder: undefined,
      polyLineArray: [],
      //logArray: [],
      isSelected: false,
      isSearch: false,
      racersArray: [],
      actualRacerMarker: undefined,
      actualRacerSpeeds: [],
      actualRacerGForces: [],
      actualLastData: [],
      modalChartLabels: [],
      selectedRacer: undefined,
      chart: undefined,
      chart2: undefined,
      polyLine: undefined,
      SearchRacerText: "",
      measureShow: true,
      storedID: "",
      draggableLines: [],

      measureArrows: [],
      bearing: undefined,

      halfwayCoord: undefined,
      isOpen: false,
      searchQuery: "",
      //-------------------------------Alert------------------------------------------
      showAlert: false,
      errorMessage: "",
      //-------------------------------------Replay------------------------------------------
      intervalTime: 1000,
      start_interval: undefined,

      playSpeed: 1,
      isPlaying: false,
      firstDataDate: undefined,
      lastDataDate: undefined,
      time: 0,
      firstAndLast: {
        firstDate: undefined,
        lastDate: undefined,
      },
      replayRacerArray: [],
      timestamp: undefined,
      CheckSectionDataBool: false,
      CheckTripDataBool: false,
      radius: 15,
      checkpointArray: [],
      replayTimeArray: [],
      positionArray: [],
      load: true,
      trailRacersBool: false,
      limit: 20, // for racer click
      trailLimit: 20, //for trail length
      trailArray: [], //Array for containing trail data. it is an array array
      trailLineArray: [], //Array for containing trail Polylines
      isChecked: true,
      displaySidebar: false,
      cirlce: undefined,
      //-------------------------------------Role Start---------------------------------------
      role: undefined,
      //-------------------------------------------------------------------------------------
      ///-------------------------------------------------------MQTT-----------------------------------------------------
      connection: {
        protocol: "wss",
        host: "rally.ddc.sze.hu",
        // ws: 8083; wss: 8084
        port: 8083,
        endpoint: "", // "/mqtt"
        rejectUnauthorized: false,

        // for more options, please refer to https://github.com/mqttjs/MQTT.js#mqttclientstreambuilder-options
        clean: true,
        connectTimeout: 30 * 1000, // ms
        reconnectPeriod: 4000, // ms
        clientId:
          "rally_frontend_" + Math.random().toString(16).substring(2, 8),
        // auth
        username: "",
        password: "",
      },
      subscription: {
        topic: "topic/mqttx",
        qos: 0,
      },
      publish: {
        topic: "topic/browser",
        qos: 0,
        payload: '{ "msg": "Hello, I am browser." }',
      },
      receiveNews: "",
      qosList: [0, 1, 2],
      client: {
        connected: false,
      },
      subscribeSuccess: false,
      connecting: false,
      retryTimes: 0,

      actualRacerGForcesRe: [],
      actualRacerSpeedsRe: [],
      modalChartLabelsRe: [],

      //------------------------------------------------------------MQTT END------------------------------------------------------------------------------------------
    };
  },
  created() {
    this.SearchRacerText = this.$t("language.SearchRacerText");
  },
  components: {
    BlackCircleButton,
    RadarSpinner,
    CustomAlert,
  },

  watch: {
    isChecked(newValue, oldValue) {
      if (newValue) {
        this.allRacer();
      } else if (oldValue) {
        this.noRacer();
        this.CheckTrailData();
      }
    },
    selectedNumber() {
      if (this.currentMarker) {
        this.getActualRacerData(this.currentMarker);
      }
    },
  load(newVal) {
    if (newVal) {
      document.body.style.pointerEvents = 'none';  
    } else {
      document.body.style.pointerEvents = 'auto'; 
    }
  }
  },

  computed: {
    filteredRacers: function () {
      return this.racersArray.filter((item) => {
        if (
          item.racer_pilot_name
            .toLowerCase()
            .includes(this.searchValue.toLowerCase()) ||
          item.racer_start_num.toString().includes(this.searchValue)
        ) {
          return true;
        } else {
          return false;
        }
      });
    },
    filteredReplayRacers: function () {
      return this.racersArray.filter((item) => {
        if (
          item.racer_start_num
            .toString()
            .toLowerCase()
            .includes(this.searchQuery) ||
          item.racer_pilot_name
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase())
        ) {
          if (this.searchValue == "") {
            this.tableCount = 1;
          }
          return true;
        } else {
          this.tableCount = 1;
          return false;
        }
      });
    },
  },

  async mounted() {
    this.legId = localStorage.getItem("leg_id");
    this.leg_id = this.legId;
    this.stageLogs = await dp.getAllStageStatusLogById(this.legId);
    this.stages = await dp.getStageByLegId(this.legId);
    this.pois = await dp.getPOIsByLegId(this.legId);
    this.legMap = await dp.getLegById(this.legId);
    this.legName = this.legMap.leg_name;
    this.legDate = this.legMap.leg_date;
    this.racersArray = await dp.getRacersByEventId(
      localStorage.getItem("event_id")
    );
    this.racersArray.sort(function (a, b) {
      return a.racer_start_num - b.racer_start_num;
    });
    //this.logArray = await dp.getEveryLogByLegId(this.legId)
    this.initMQTTData();
    this.createMQTTConnection();
    //this.doMQTTSubscribe("replay/${uuid}/device_logs/${device_physical_id}", 0);
    //this.doMQTTSubscribe("replay/${uuid}/stage_status_log", 0);
    this.firstAndLast = await dp.getFirstAndLastDate(this.leg_id);
    this.timestamp = DateTime.fromISO(this.firstAndLast.firstDate).toMillis();
    this.lastDeviceArray = await dp.getLastDeviceLogByTimeStamp(
      this.legId,
      this.timestamp
    );

    this.isPlaying = false;
    this.checkpointArray = this.replayRacerArray;
    //-------------------------------------Role Start---------------------------------------
    let token = localStorage.getItem("JWT");
    let decodedToken = jwt_decode(token);
    this.role = decodedToken[0].role_type;
    //-------------------------------------------------------------------------------------

    this.setupLeafletMap();
    if (!this.mapDiv) {
      return;
    }
    this.mapDiv.createPane("highlightPane");
    this.mapDiv.getPane("highlightPane").style.zIndex = 399;

    //sdasdadw3qqd

    this.load = false;
    document.addEventListener("click", this.outsideClick);
    this.allRacer();
  },

  beforeUnmounted() {
    document.removeEventListener("click", this.outsideClick);
  },

  methods: {
    // Egy pulzáló hatás létrehozása egy időzített függvény segítségével
    pulseEffect(polylineColor, layer) {
      var count = 0;
      var originalColor = polylineColor;
      var pulseTimer = setInterval(function () {
        if (count % 2 === 0) {
          layer.setStyle({ color: "black" }); // Fekete szín
        } else {
          layer.setStyle({ color: originalColor }); // Vagy más szín, amit szeretnél
        }
        count++;
        if (count > 8) {
          // 10-szer pulzál, 3 másodperc alatt
          clearInterval(pulseTimer); // Időzítő leállítása
          layer.setStyle({ color: "black" }); // Visszaállítjuk a fekete színt
        }
      }, 300); // 300ms-es intervallum (0.3 másodperc)
    },

    checkPopupId(id) {
      if (this.popUpId != id) {
        this.popUpId = id;
      } else {
        this.popUpId = 0;
      }
    },

    outsideClick(e) {
      if (e.target.id == "racerSearchBar") {
        this.isSearch = true;
      } else {
        this.isSearch = false;
      }
    },

    checkLineSpeed(point1, point2) {
      let color = "blue";
      if (point2.velocity <= 50) {
        color = "#1034A6";
      } else if (point2.velocity > 50 && point2.velocity <= 70) {
        color = "#412F88";
      } else if (point2.velocity > 70 && point2.velocity <= 90) {
        color = "#722B6A";
      } else if (point2.velocity > 90 && point2.velocity <= 110) {
        color = "#A2264B";
      } else if (point2.velocity > 110 && point2.velocity <= 130) {
        color = "#D3212D";
      } else {
        color = "#F62D2D";
      }
      return color;
    },
    /* //old drawtrail
     drawTrail(trailArray, point1, point2, index) {
      //itt kell kirajzolni, meg kell jeleníteni a csíkokat a trailArray alapján
      if (trailArray) {
        //draw line by array
        //for array

          for (
            let arrayIndex = 0;
            arrayIndex < trailArray.length - 1;
            arrayIndex++
          ) {
            //check speed
            let color = this.checkLineSpeed(
              trailArray[arrayIndex],
              trailArray[arrayIndex + 1]
            );
            //draw line from point[i],point[i+1]
            let polyLine = L.polyline(
              [
                [
                  trailArray[arrayIndex].latitude,
                  trailArray[arrayIndex].longitude,
                ],
                [
                  trailArray[arrayIndex + 1].latitude,
                  trailArray[arrayIndex + 1].longitude,
                ],
              ],
              { color: color }
            ).addTo(this.mapDiv);
            this.trailLineArray[index].push(polyLine);
          }
      } else {
        //draw line by point 1 and point2
        if (point1 && point2) {
          let color = this.checkLineSpeed(point1, point2);
          let polyLine = L.polyline(
            [
              [point1.latitude, point1.longitude],
              [point2.latitude, point2.longitude],
            ],
            { color: color }
          ).addTo(this.mapDiv);
          this.trailLineArray[index].push(polyLine);

          if (this.trailLineArray[index].length > this.trailLimit) {
            this.trailLineArray[index][0].remove();
            this.trailLineArray[index].shift();
          }
        }
        //check speed for color
        //draw line between 2 points
      }
    }, */
    //new drawtrail
    drawTrail(trailArray, point1, point2, index) {
      //const maxTimeGap = 60 * 1000;
      if (!this.trailLineArray[index]) {
        this.trailLineArray[index] = [];
      }
      if (trailArray) {
        for (
          let arrayIndex = 0;
          arrayIndex < trailArray.length - 1;
          arrayIndex++
        ) {
          /* const timeDifference = Math.abs(
            new Date(trailArray[arrayIndex + 1].timestamp) -
              new Date(trailArray[arrayIndex].timestamp)
          );
          if (timeDifference <= maxTimeGap) { */
          let color = this.checkLineSpeed(
            trailArray[arrayIndex],
            trailArray[arrayIndex + 1]
          );
          let polyLine = L.polyline(
            [
              [
                trailArray[arrayIndex].latitude,
                trailArray[arrayIndex].longitude,
              ],
              [
                trailArray[arrayIndex + 1].latitude,
                trailArray[arrayIndex + 1].longitude,
              ],
            ],
            { color: color }
          ).addTo(this.mapDiv);
          this.trailLineArray[index].push(polyLine);
          // }
        }
      } else if (point1 && point2) {
        let color = this.checkLineSpeed(point1, point2);
        let polyLine = L.polyline(
          [
            [point1.latitude, point1.longitude],
            [point2.latitude, point2.longitude],
          ],
          { color: color }
        ).addTo(this.mapDiv);
        this.trailLineArray[index].push(polyLine);
        if (this.trailLineArray[index].length > this.trailLimit) {
          this.trailLineArray[index][0].remove();
          this.trailLineArray[index].shift();
        }
      }
    },
    async getTrailData() {
      for (let index = 0; index < this.replayRacerArray.length; index++) {
        this.trailArray.push([]);
        this.trailLineArray.push([]);

        this.trailArray[index] = await dp.getLogsBytimeandLimit(
          this.legId,
          this.trailLimit,
          this.timestamp,
          this.replayRacerArray[index],
          this.legDate
        );
        this.drawTrail(this.trailArray[index], undefined, undefined, index);
      }
    },

    async getMultiTimeTrailData() {
      for (let index = 0; index < this.replayRacerArray.length; index++) {
        this.trailArray.push([]);
        this.trailLineArray.push([]);
        this.trailArray[index] = await dp.getLogsBytimeandLimit(
          this.legId,
          this.trailLimit,
          this.replayTimeArray[index],
          this.replayRacerArray[index],
          this.legDate
        );

        this.drawTrail(this.trailArray[index], undefined, undefined, index);
      }
    },

    async trailRacers() {
      if (this.replayRacerArray.length > 0) {
        this.trailRacersBool = !this.trailRacersBool;

        if (!this.CheckTripDataBool && this.trailRacersBool) {
          //basic replay by time
          this.load = true;
          if (this.load) {
            document.body.style.pointerEvents = 'none';
          } else {
            document.body.style.pointerEvents = 'auto';
          }
          await this.getTrailData();
          this.load = false;
        } else if (this.CheckTripDataBool && this.trailRacersBool) {
          //Multitime query trails
          this.load = true;
          await this.clearTrails();
          await this.getMultiTimeTrailData();
          this.load = false;
        }

        if (!this.trailRacersBool) {
          this.trailArray = [];
          for (const item of this.trailLineArray) {
            for (const polyLine of item) {
              polyLine.remove(this.mapDiv);
            }
          }
        }
      } else {
        /* alert("Please select Racer to start replay with trail"); */
        this.showAlert = true;
        this.errorMessage = "Please select Racer to start replay with trail";
      }
    },
    async decreaseTimestamp(amount) {
      this.timestamp -= amount;
      this.carMarkerHandler();
      if (this.trailRacersBool) {
        await this.clearTrails();
        await this.getTrailData();
      }
      this.reloadChartData();
      if (
        this.timestamp <
        DateTime.fromISO(this.firstAndLast.firstDate).toMillis()
      ) {
        this.timestamp = DateTime.fromISO(
          this.firstAndLast.firstDate
        ).toMillis();
      }
    },
    async increaseTimestamp(amount) {
      this.timestamp = Number(this.timestamp) + Number(amount);
      this.carMarkerHandler();
      if (this.trailRacersBool) {
        await this.clearTrails();
        await this.getTrailData();
      }
      this.reloadChartData();
      if (
        this.timestamp > DateTime.fromISO(this.firstAndLast.lastDate).toMillis()
      ) {
        this.timestamp = DateTime.fromISO(
          this.firstAndLast.lastDate
        ).toMillis();
      }
    },
    getPositionRecordedAt(racerId) {
      let finalTimeArray = [];
      this.positionArray.filter((item) => {
        if (item.racer_id === racerId) {
          finalTimeArray.push(this.getTimeFromDateTime(item.recorded_at));
          return true;
        }
      });

      finalTimeArray.join(",", "\n");
      return finalTimeArray;
    },

    getTimeFromDateTime(dateTime) {
      return DateTime.fromISO(dateTime).toLocaleString({
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hourCycle: "h23",
      });
    },
    allRacer() {
      for (let index = 0; index < this.racersArray.length; index++) {
        if (!this.replayRacerArray.includes(this.racersArray[index].racer_id)) {
          if (
            document.getElementById(
              "racerChk" + this.racersArray[index].racer_id
            )
          ) {
            document.getElementById(
              "racerChk" + this.racersArray[index].racer_id
            ).checked = true;
            this.replayRacerArray.push(this.racersArray[index].racer_id);
            if (this.CheckTripDataBool) {
              document.getElementById(
                "racerInputChk" + this.racersArray[index].racer_id
              ).disabled = false;
              this.replayTimeArray.push(this.racersArray[index].racer_id);
            }
          }
        }
      }
    },
    noRacer() {
      if (!this.isPlaying && !this.trailRacersBool) {
        for (const carMarker of this.carMarkerArray) {
          if (carMarker.length == undefined) {
            carMarker.remove();
          }
        }
        for (const carMarker2 of this.carMarkerArray2) {
          if (carMarker2.length == undefined) {
            carMarker2.remove();
          }
        }
        this.carMarkerArray = [];
        this.carMarkerArray2 = [];
        for (let index = 0; index < this.racersArray.length; index++) {
          document.getElementById(
            "racerChk" + this.racersArray[index].racer_id
          ).checked = false;
          this.replayRacerArray = [];
          if (this.CheckTripDataBool) {
            document.getElementById(
              "racerInputChk" + this.racersArray[index].racer_id
            ).disabled = true;
            document.getElementById(
              "racerInputChk" + this.racersArray[index].racer_id
            ).value = 0;
            this.replayTimeArray = [];
          }
        }
      }
    },
    selectRacer(event, racer_id) {
      let checkbox = event.target;

      if (checkbox.checked) {
        if (!this.replayRacerArray.includes(racer_id)) {
          this.replayRacerArray.push(racer_id);
          document.getElementById("racerInputChk" + racer_id).disabled = false;
          this.replayTimeArray.push("00:00");
        }
      } else {
        let index = this.replayRacerArray.indexOf(racer_id);
        if (index !== -1) {
          this.replayRacerArray.splice(index, 1);
          document.getElementById("racerInputChk" + racer_id).disabled = true;
          document.getElementById("racerInputChk" + racer_id).value = 0;
          this.replayTimeArray.splice(index, 1);
        }
      }
    },
    changeTime(event, item) {
      let index = this.replayRacerArray.indexOf(item.racer_id);
      this.replayTimeArray[index] = event.target.value;
    },
    //Trail Line when slider change
    //még rossz
    async handleTimeSliderChange() {
     
      if (this.isPlaying) {
        this.load = true;
        await this.stopReplay();
        await this.clearTrails();
        await this.startReplay();
        this.load = false;
      } else {
        this.load = true;
        this.clearTrails();
        await this.carMarkerHandler();
        this.load = false;
        if (this.trailRacersBool) {
        this.load = true;
          await this.getTrailData();
          this.load = false;
        }
      }
      this.reloadChartData();
    },

    clearTrails() {
      for (let index = 0; index < this.trailLineArray.length; index++) {
        if (this.trailLineArray[index] != undefined) {
          while (this.trailLineArray[index].length > 0) {
            const polyline = this.trailLineArray[index].shift();
            polyline.remove();
          }
        }
      }
      this.trailLineArray = [];
    },
    //-----------------------------------
    async startReplay() {
      if (this.replayRacerArray.length != 0) {
        this.carMarkerHandler();
        if (this.trailRacersBool) {
          await this.getTrailData();
        }
        this.startResponse = await dp.startReplayByTime(
          this.leg_id,
          this.timestamp,
          this.replayRacerArray
        );
        if (this.startResponse) {
          for (let index = 0; index < this.racersArray.length; index++) {
            if (
              document.getElementById(
                "racerChk" + this.racersArray[index].racer_id
              )
            ) {
              document.getElementById(
                "racerChk" + this.racersArray[index].racer_id
              ).disabled = true;
            }
          }

          this.doMQTTSubscribe(
            `${topic_prefix}${replay_topic}/${this.startResponse.replayUuid}/device_logs/#`,
            0
          );
          this.doMQTTSubscribe(
            `${topic_prefix}${replay_topic}/${this.startResponse.replayUuid}/stage_status_log`,
            0
          );
          this.start_interval = setInterval(() => {
            this.timestamp = Number(this.timestamp) + this.intervalTime;
            if (this.timestamp+5000>new Date(this.firstAndLast.lastDate).getTime()) {
              //console.log("STOPPPPP");
              
              this.stopReplay()
              this.isPlaying=false
            }
          }, this.intervalTime);
        }
      } else {
        this.isPlaying = false;
        /* alert("Please select Racer to start replay"); */
        this.showAlert = true;
        this.errorMessage = "Please select Racer to start replay";
      }
    },
    async startMultiTimeReplay() {
      if (
        this.replayRacerArray.length != 0 &&
        this.replayTimeArray.length != 0
      ) {
        //this.carMarkerHandler();
        if (this.trailRacersBool) {
          await this.clearTrails();
          await this.getMultiTimeTrailData();
        }
        this.startResponse = await dp.startReplayByMultipleTime(
          this.leg_id,
          this.replayTimeArray,
          this.replayRacerArray,
          this.legDate
        );
        if (this.startResponse) {
          this.doMQTTSubscribe(
            `${topic_prefix}${replay_topic}/${this.startResponse.replayUuid}/device_logs/#`,
            0
          );
          this.doMQTTSubscribe(
            `${topic_prefix}${replay_topic}/${this.startResponse.replayUuid}/stage_status_log`,
            0
          );
          for (let index = 0; index < this.racersArray.length; index++) {
            if (
              document.getElementById(
                "racerChk" + this.racersArray[index].racer_id
              )
            ) {
              document.getElementById(
                "racerChk" + this.racersArray[index].racer_id
              ).disabled = true;
            }
          }

          /*  this.start_interval = setInterval(() => {
            this.timestamp = Number(this.timestamp) + this.intervalTime;
          }, this.intervalTime); */
        }
      } else {
        this.isPlaying = false;
        /* alert("Please select Racer to start replay"); */
        this.showAlert = true;
        this.errorMessage = "Please select Racer to start replay";
      }
    },
    async stopReplay() {
      if (this.startResponse) {
        await dp.stopReplay(this.startResponse.replayUuid);
        clearInterval(this.start_interval);
        for (let index = 0; index < this.racersArray.length; index++) {
          if (
            document.getElementById(
              "racerChk" + this.racersArray[index].racer_id
            )
          ) {
            document.getElementById(
              "racerChk" + this.racersArray[index].racer_id
            ).disabled = false;
          }
        }
        this.doMQTTUnSubscribe(
          `${replay_topic}/${this.startResponse.replayUuid}/device_logs/#`
        );
        this.doMQTTUnSubscribe(
          `${replay_topic}/${this.startResponse.replayUuid}/stage_status_log`
        );
      }
    },

    bigIcons() {
      for (let i = 0; i < this.carMarkerArray.length; i++) {
        let marker = this.carMarkerArray[i];
        marker.setIcon(
          L.icon({
            iconUrl: marker.options.icon.options.iconUrl,
            iconAnchor: [15, 15],
            iconSize: [45, 45],
            rotationAngle: marker.options.icon.options.rotationAngle,
            rotationOrigin: "center",
          })
        );
      }
      for (let i = 0; i < this.carMarkerArray2.length; i++) {
        let marker = this.carMarkerArray2[i];
        marker.setIcon(
          L.divIcon({
            iconAnchor: [8, 10],
            iconSize: [35],
            className: "my-div-icon",
            html:
              '<b style="font-size:14px;display:inline-block;text-align:center;vertical-align:middle;width:30px;height:45px;margin-top:6px; margin-right:4px; position: relative">' +
              this.lastDeviceArray[i].racer_start_num +
              "</b>",
          })
        );
      }
      document.getElementsByClassName("smallBtn")[0].style.backgroundColor =
        "#dd9c44";
      document.getElementsByClassName("largeBtn")[0].style.backgroundColor =
        "#FEC31A";
    },

    smallIcons() {
      for (let i = 0; i < this.carMarkerArray.length; i++) {
        let marker = this.carMarkerArray[i];
        marker.setIcon(
          L.icon({
            iconUrl: marker.options.icon.options.iconUrl,
            iconAnchor: [20, 20],
            iconSize: [40, 40],
            rotationAngle: marker.options.icon.options.rotationAngle,
            rotationOrigin: "center",
          })
        );
      }
      for (let i = 0; i < this.carMarkerArray2.length; i++) {
        let marker = this.carMarkerArray2[i];
        marker.setIcon(
          L.divIcon({
            iconAnchor: [22.5, 11],
            iconSize: [20, 20],
            className: "my-div-icon",
            html:
              '<b style="font-size:10.5px;display:inline-block;text-align:center;vertical-align:middle;width:45px;height:45px;margin-top:5px; margin-right:2000px; position: relative">' +
              this.lastDeviceArray[i].racer_start_num +
              "</b>",
          })
        );
      }
      document.getElementsByClassName("largeBtn")[0].style.backgroundColor =
        "#dd9c44";
      document.getElementsByClassName("smallBtn")[0].style.backgroundColor =
        "#FEC31A";
    },

    activateSearch() {
      this.isSearch = true;
      this.searchValue = "";
    },

    navToProjector() {
      this.$router.push("/projectorPage");
    },

    async showRacer(item) {
      for (let i = 0; i < this.lastDeviceArray.length; i++) {
        if (item.racer_id == this.lastDeviceArray[i].racer_id) {
          this.mapDiv.setView([
            this.lastDeviceArray[i].latitude,
            this.lastDeviceArray[i].longitude,
          ]);
          this.searchValue = item.racer_pilot_name;
        }
      }
    },

    async showReplayRacer(item) {
      for (let i = 0; i < this.lastDeviceArray.length; i++) {
        if (item.racer_id == this.lastDeviceArray[i].racer_id) {
          this.mapDiv.setView([
            this.lastDeviceArray[i].latitude,
            this.lastDeviceArray[i].longitude,
          ]);
        }
      }
    },

    getDwellTime(stage_id) {
      return document.getElementById("dwellTimeInput" + stage_id).value;
    },
    getSpeedLimit(stage_id) {
      return document.getElementById("speedLimitInput" + stage_id).value;
    },
    setupLeafletMap: function () {
      const mapParams = (L.MapOptions = {
        zoomControl: false,
        zoomAnimation: false,
        doubleClickZoom: false,
        scrollWheelZoom: true,
        attributionControl: false,
      });
      if (!document.getElementById("mapContainer")) {
        return;
      }
      this.mapDiv = L.map("mapContainer", mapParams).setView(this.center, 13);

      this.mapDiv.on("click", async (e) => {
        try {
          if (this.CheckSectionDataBool) {
            if (this.replayRacerArray.length > 0) {
              let coord = e.latlng;
              let lat = coord.lat;
              let lng = coord.lng;

              if (this.circle) {
                this.circle.remove();
                this.circle = undefined;
              }
              this.circle = L.circle([lat, lng], {
                radius: this.radius,
                color: "#f2d480",
              })
                .on("click", async () => {
                  this.circle.remove();
                  this.circle = undefined;
                })
                .addTo(this.mapDiv);
              this.positionArray = await dp.getDeviceLogsByPosition(
                this.leg_id,
                lat,
                lng,
                this.replayRacerArray,
                this.radius
              );
            } else {
              this.showAlert = true;
              this.errorMessage = "Please, select at least one Racer!";
            }
          }
        } catch (error) {
          console.error(error);
        }
      });

      this.setLightLayer();
      //-----------------------------------STAGE HANDLER------------------------------------------
      for (let i = 0; i < this.stages.length; i++) {
        let stageCoordData = JSON.parse(this.stages[i].stage_line);

        let latlngs = stageCoordData.coordinates;

        this.newCenter = latlngs[1];
        let color = undefined;
        if (this.stageLogs[i].stage_status == "normal") {
          color = "green";
        }
        if (this.stageLogs[i].stage_status == "red flag") {
          color = "red";
        }
        if (this.stageLogs[i].stage_status.includes("limit")) {
          color = "yellow";
        } else if (this.stageLogs[i].stage_status == "transport") {
          color = "blue";
        }
        this.polyLine = undefined;
        this.polyLine = L.polyline(latlngs, {
          color: color,
          id: "stageLine" + this.stages[i].stage_id,
        }).addTo(this.mapDiv);
        this.polyLine.on("click", async (e) => {
          if (this.CheckSectionDataBool == false) {
            let highlightedElements =
              document.querySelectorAll(".highlighted-tr");

            highlightedElements.forEach(function (element) {
              element.classList.remove("highlighted-tr");
            });

            // Ha a "bc3" elem még nincs megnyitva
            if (!this.isOpen) {
              // Megnyitja a "bc3" elemet
              document.getElementById("bc3").click();
              // Várunk egy rövid időt (pl.: 100 milliszekundum), hogy a "bc3" elem betöltődjön
              setTimeout(() => {
                // A kód folytatása a "bc3" elem megnyitása után
                let idString = e.target.options.id.toString().slice(9);

                if (this.highlightBorder != undefined) {
                  this.mapDiv.removeLayer(this.highlightBorder);
                }

                let combinedString = ("table-tr" + idString).toString();

                document
                  .getElementById(combinedString)
                  .classList.add("highlighted-tr");
                document
                  .getElementById(combinedString)
                  .scrollIntoView({ behavior: "smooth", block: "center" });
                this.highlightBorder = L.polyline(latlngs, {
                  color: "black",
                  weight: "7",
                  pane: "highlightPane",
                }).addTo(this.mapDiv);
              }, 100); // Állítsa be a megfelelő késleltetést szükség szerint
            } else {
              let idString = e.target.options.id.toString().slice(9);

              if (this.highlightBorder != undefined) {
                this.mapDiv.removeLayer(this.highlightBorder);
              }

              let combinedString = ("table-tr" + idString).toString();

              document
                .getElementById(combinedString)
                .classList.add("highlighted-tr");
              document
                .getElementById(combinedString)
                .scrollIntoView({ behavior: "smooth", block: "center" });
              this.highlightBorder = L.polyline(latlngs, {
                color: "black",
                weight: "7",
                pane: "highlightPane",
              }).addTo(this.mapDiv);
            }
          } else {
            //CheckSectionDataBool TRUE
          }
        });
        this.polyLineArray.push(this.polyLine);
      }
      this.mapDiv.setView(this.newCenter);

      this.poiHandler();
      this.carMarkerHandler();

      //---------------------------------MAP CONTROLS-----------------------------------------------
      L.control
        .zoom({
          position: "bottomright",
        })
        .addTo(this.mapDiv);
    },
    setMapViewButtonClick(index, id) {
      let highlightedElements = document.querySelectorAll(".highlighted-tr");
      let trID = id.toString();
      if (this.storedID != trID) {
        document.getElementById(trID).classList.remove("highlighted-tr");
      }
      document.getElementById(trID).classList.add("highlighted-tr");
      this.storedID = trID;
      highlightedElements.forEach(function (element) {
        element.classList.remove("highlighted-tr");
      });
      this.setMapView(index);
    },
    //-------------------------------CheckSectionData---------------------------
    CheckSectionData() {
      this.CheckSectionDataBool = !this.CheckSectionDataBool;
      if (this.CheckSectionDataBool) {
        document.getElementById("CheckSectionData-btn").style.backgroundColor =
          "orange";
      } else {
        document.getElementById("CheckSectionData-btn").style.backgroundColor =
          "black";
        document
          .getElementById("CheckSectionData-btn")
          .style.removeProperty("background-color");
        if (this.circle) {
          this.circle.remove();
          this.circle = undefined;
        }
      }
    },

    CheckTripData() {
      this.CheckTripDataBool = !this.CheckTripDataBool;
      if (this.CheckTripDataBool) {
        document.getElementById("CheckTripData-btn").style.backgroundColor =
          "orange";
      } else {
        document.getElementById("CheckTripData-btn").style.backgroundColor =
          "black";
        document
          .getElementById("CheckTripData-btn")
          .style.removeProperty("background-color");
      }
    },
    CheckTrailData() {
      if (this.trailRacersBool) {
        document.getElementById("trailBtn").style.backgroundColor = "#B57A0C";
        document.getElementById("CheckTripData-btn").disabled = true;
        document.getElementById("CheckSectionData-btn").disabled = true;
        let elements = document.getElementsByClassName("racerChk");
        for (let i = 0; i < elements.length; i++) {
          elements[i].disabled = true;
        }
      } else {
        document.getElementById("trailBtn").style.backgroundColor = "orange";
        document
          .getElementById("trailBtn")
          .style.removeProperty("background-color");
        document.getElementById("CheckTripData-btn").disabled = false;
        document.getElementById("CheckSectionData-btn").disabled = false;
        let elements = document.getElementsByClassName("racerChk");
        for (let l = 0; l < elements.length; l++) {
          elements[l].disabled = false;
        }
      }
    },
    //---------------------------------------------------------MEASURE TOOL-----------------------------------------------------------------------------------------

    changeMeasure() {
      event.stopPropagation();
      this.measure = !this.measure;
      if (this.measure == true) {
        document.getElementById("mapContainer").style.cursor = "crosshair";
        this.mapDiv.on("click", this.measureDistance);
        this.mapDiv.on("click", this.calculateTotalDistance);
        var currentZoom = this.mapDiv.getZoom();
        this.mapDiv.setMaxZoom(currentZoom);
        this.mapDiv.setMinZoom(currentZoom);
      } else if (this.measure == false) {
        this.mapDiv.off("click", this.calculateTotalDistance);
        document.getElementById("mapContainer").style.cursor = "auto";
        this.mapDiv.off("click", this.measureDistance);

        for (let i = 0; i < this.measureMarkers.length; i++) {
          this.mapDiv.removeLayer(this.measureMarkers[i]);
        }
        for (let i = 0; i < this.measureLines.length; i++) {
          this.mapDiv.removeLayer(this.measureLines[i]);
        }
        for (let i = 0; i < this.measureArrows.length; i++) {
          this.mapDiv.removeLayer(this.measureArrows[i]);
        }

        this.measureLines = [];
        this.measureMarkers = [];
        this.measureArrows = [];
        this.totalDistance = 0;
        this.totalDistanceString = "0 m";
        this.uniqueDistances = [];
        this.distanceArray = [];
        this.mapDiv.setMaxZoom(22);
        this.mapDiv.setMinZoom(1);
      }
    },

    calculateBearing(coord1, coord2) {
      const y = Math.sin(coord2[1] - coord1[1]) * Math.cos(coord2[0]);
      const x =
        Math.cos(coord1[0]) * Math.sin(coord2[0]) -
        Math.sin(coord1[0]) *
          Math.cos(coord2[0]) *
          Math.cos(coord2[1] - coord1[1]);
      const bearing = (Math.atan2(y, x) * 180) / Math.PI;
      this.bearing = 360 - ((bearing + 360) % 360);
    },
    calculateHalfwayPoint(coord1, coord2) {
      const lat1 = coord1[0];
      const lon1 = coord1[1];
      const lat2 = coord2[0];
      const lon2 = coord2[1];

      const dLon = this.toRadians(lon2 - lon1);
      const lat1Rad = this.toRadians(lat1);
      const lat2Rad = this.toRadians(lat2);
      const lon1Rad = this.toRadians(lon1);

      const Bx = Math.cos(lat2Rad) * Math.cos(dLon);
      const By = Math.cos(lat2Rad) * Math.sin(dLon);
      const lat3 = Math.atan2(
        Math.sin(lat1Rad) + Math.sin(lat2Rad),
        Math.sqrt((Math.cos(lat1Rad) + Bx) * (Math.cos(lat1Rad) + Bx) + By * By)
      );
      const lon3 = lon1Rad + Math.atan2(By, Math.cos(lat1Rad) + Bx);

      this.halfwayCoord = [this.toDegrees(lat3), this.toDegrees(lon3)];
    },
    toRadians(degrees) {
      return degrees * (Math.PI / 180);
    },
    toDegrees(radians) {
      return radians * (180 / Math.PI);
    },

    measureDistance(e) {
      const haversine = require("haversine");
      const { lat, lng } = e.latlng;
      let measureIcon = L.icon({
        iconUrl: "measureDot.png",
        iconSize: [15, 15],
      });

      this.measureMarker = L.marker([lat, lng], {
        icon: measureIcon,
      }).addTo(this.mapDiv);
      // this.measureMarker.on('drag', () =>{
      //   this.dragLines();
      // })

      this.measureMarkers.push(this.measureMarker);
      // for (let i = 1; i < this.measureMarkers.length; i++) {

      let i = this.measureMarkers.length - 1;
      if (i > 0) {
        let start = {
          latitude: this.measureMarkers[i - 1]._latlng.lat,
          longitude: this.measureMarkers[i - 1]._latlng.lng,
        };
        let end = {
          latitude: this.measureMarkers[i]._latlng.lat,
          longitude: this.measureMarkers[i]._latlng.lng,
        };

        this.calculateHalfwayPoint(
          [start.latitude, start.longitude],
          [end.latitude, end.longitude]
        );

        this.bearing = 0;

        this.calculateBearing(
          [start.latitude, start.longitude],
          [end.latitude, end.longitude]
        );

        let measureArrow = L.icon({
          iconUrl: "arrow.png",
          iconSize: [14, 14],
          iconAnchor: [7, 7],
        });

        let measureArrowIcon = L.marker(this.halfwayCoord, {
          icon: measureArrow,
          rotationAngle: this.bearing,
        }).addTo(this.mapDiv);

        this.measureArrows.push(measureArrowIcon);
        this.distance = 0;
        this.distance = haversine(start, end, { unit: "meter" });

        this.distanceArray.push(this.distance);

        let distanceString = this.distance.toString().slice(0, 8) + " m";
        this.measureMarker.bindTooltip(distanceString).openTooltip();

        let polyLine = L.polyline([
          [
            this.measureMarkers[i - 1]._latlng.lat,
            this.measureMarkers[i - 1]._latlng.lng,
          ],
          [
            this.measureMarkers[i]._latlng.lat,
            this.measureMarkers[i]._latlng.lng,
          ],
        ])
          .setStyle({ color: "#dd9c44" })
          .addTo(this.mapDiv);

        this.measureLines.push(polyLine);
      }
    },

    // dragLines(){
    // },

    async calculateTotalDistance() {
      let uniqueSet = new Set(this.distanceArray);
      this.uniqueDistances = Array.from(uniqueSet);

      let length = this.uniqueDistances.length;

      if (length > 0) {
        this.totalDistance += this.uniqueDistances[length - 1];
      }

      this.totalDistanceString =
        this.totalDistance.toString().slice(0, 8) + " m";
    },

    //---------------------------------------------------------POI HANDLING-----------------------------------------------------------------------------------------
    poiHandler() {
      for (let i = 0; i < this.pois.length; i++) {
        let marker = [
          Number(this.pois[i].poi_latitude),
          Number(this.pois[i].poi_longitude),
        ];
        this.markers.push(marker);
      }
      for (let i = 0; i < this.markers.length; i++) {
        let poiIcon;

        if (this.pois[i].poi_type.toLowerCase() == "radio") {
          poiIcon = L.divIcon({
            className: "radio-marker-icon",
            html: `<div style="position: relative;"><img src="icons/radio.png" alt="Radio Icon" style="width: 38px; height: 20px;"><span style="position: absolute; top: 50%; left: 230%; transform: translate(-50%, -50%);">${this.pois[
              i
            ].poi_name
              .split("|")[0]
              .replace("#", "")
              .trim()}</span></div>`,
            iconUrl: "icons/radio.png",
          });
        } else {
          poiIcon = L.icon({
            iconUrl: "icons/" + this.pois[i].poi_type.toLowerCase() + ".png",
            iconSize: [25, 25],
          });
        }

        this.poiTypeCounter = 0;

        for (let j = 0; j < this.poiTypes.length; j++) {
          if (
            this.poiTypes[j].toLowerCase() ==
            this.pois[i].poi_type.toLowerCase()
          ) {
            this.poiTypeCounter++;
          }
        }

        if (this.poiTypeCounter == 0) {
          this.poiTypes.push(this.pois[i].poi_type.toLowerCase());
        }

        let markerElement = L.marker(this.markers[i], {
          icon: poiIcon,
        }).addTo(this.mapDiv);

        const popupContent = `
        <table>
          <tr>
            <th>Name &ensp;</th>
            <td>${this.pois[i].poi_name}</td>
          </tr>
          <tr>
            <th>Type &ensp;</th>
            <td>${this.pois[i].poi_type.toLowerCase()}</td>
          </tr>
          <tr>
            <th>Description &ensp;</th>
            <td>${this.pois[i].poi_description}</td></tr>
        </table>
      `;

        markerElement.bindPopup(popupContent);

        markerElement.on("mouseover", function () {
          this.openPopup();
        });
        markerElement.on("mouseout", function () {
          this.closePopup();
        });

        this.markerElements.push(markerElement);
      }
    },

    /*  async carMarkerHandler() {
      this.load = true;
      for (const carMarker of this.carMarkerArray) {
        if (carMarker.length == undefined) {
          carMarker.remove();
        }
      }
      for (const carMarker2 of this.carMarkerArray2) {
        if (carMarker2.length == undefined) {
          carMarker2.remove();
        }
      }
      this.lastDeviceArray = await dp.getLastDeviceLogByTimeStamp(
        this.legId,
        this.timestamp
      );
      this.carMarkerArray = [];
      this.carMarkerArray2 = [];
      for (let i = 0; i < this.lastDeviceArray.length; i++) {
        if (this.replayRacerArray.includes(this.lastDeviceArray[i].racer_id)) {
          let marker = [
            parseFloat(this.lastDeviceArray[i].latitude),
            parseFloat(this.lastDeviceArray[i].longitude),
          ];
          let carIcon = L.icon({
            iconUrl:
              "icons/carIcons/" +
              "black.webp",
            iconAnchor: [20, 20],
            iconSize: [40, 40],
            rotationAngle: this.lastDeviceArray[i].bearing,
            rotationOrigin: "center",
            startnum: this.lastDeviceArray[i].racer_start_num,
            racer_id: this.lastDeviceArray[i].racer_id,
            racerStatus: this.lastDeviceArray[i].racer_status,
          });
          let carIcon2 = L.divIcon({
            iconAnchor: [8, 10],
            iconSize: [20],
            startnum: this.lastDeviceArray[i].racer_start_num,
            racer_id: this.lastDeviceArray[i].racer_id,

            className: "my-div-icon",
            html:
              '<b  style="font-size:10.5px;display:inline-block;text-align:center;vertical-align:middle;width:15px;height:15px;margin-top:4px; margin-right:4px; position: relative">' +
              this.lastDeviceArray[i].racer_start_num +
              "</b>",
          });

          let Marker = L.marker(marker, {
            icon: carIcon,
            autoPanOnFocus: false,
          }).addTo(this.mapDiv);

          let Marker2 = L.marker(marker, {
            icon: carIcon2,
            autoPanOnFocus: false,
          })
            .addTo(this.mapDiv)
            .on("click", this.getActualRacerData);

          this.carMarkerArray.push(Marker);
          this.carMarkerArray2.push(Marker2);
        } else {
          this.carMarkerArray.push([]);
          this.carMarkerArray2.push([]);
        }
      }
      this.load = false;
    }, */
    async carMarkerHandler() {
      this.load = true;

      // meglévő markerek törlése
      for (const carMarker of this.carMarkerArray) {
        if (carMarker.length == undefined) {
          carMarker.remove();
        }
      }
      for (const carMarker2 of this.carMarkerArray2) {
        if (carMarker2.length == undefined) {
          carMarker2.remove();
        }
      }

      // Új markerek betöltése az aktuális időponthoz
      this.lastDeviceArray = await dp.getLastDeviceLogByTimeStamp(
        this.legId,
        this.timestamp
      );

      this.carMarkerArray = [];
      this.carMarkerArray2 = [];

      for (let i = 0; i < this.lastDeviceArray.length; i++) {
        if (this.replayRacerArray.includes(this.lastDeviceArray[i].racer_id)) {
          let marker = [
            parseFloat(this.lastDeviceArray[i].latitude),
            parseFloat(this.lastDeviceArray[i].longitude),
          ];
          let status = this.lastDeviceArray[i].racer_status;
          let isTransporting = false;
          if (this.lastDeviceArray[i].racer_status === "SOS") {
            status = "SOS";
          } else if (this.lastDeviceArray[i].racer_status === "stopped") {
                  status = "stopped";
                } else {
            for (let index = 0; index < this.stages.length; index++) {
              if (
                this.stageLogs[index]?.stage_id ==
                  this.lastDeviceArray[i].closest_stage &&
                this.stageLogs[index].stage_status == "transport"
              ) {
                isTransporting = true;
                if (
                  this.lastDeviceArray[i].velocity >= 0 &&
                  this.lastDeviceArray[i].velocity <= 10
                ) {
                  status = "stoppedTransport";
                } else {
                  status = "movingTransport";
                }
                break;
              }
            }
            if (!isTransporting) {
              status = this.lastDeviceArray[i].racer_status;
            }
          }
          let iconColor;
          switch (status) {
            case "normal":
              iconColor = "black";
              break;
            case "SOS":
              iconColor = "red";
              break;
            case "OK":
              iconColor = "green";
              break;
            case "stoppedTransport":
              iconColor = "grey";
              break;
            case "movingTransport":
              iconColor = "white";
              break;
            case "stopped":
              iconColor = "yellow";
              break;
            default:
              iconColor = "black";
              break;
          }

          let carIcon = L.icon({
            iconUrl: "icons/carIcons/" + iconColor + ".webp",
            iconAnchor: [20, 20],
            iconSize: [40, 40],
            rotationAngle: this.lastDeviceArray[i].bearing,
            rotationOrigin: "center",
            startnum: this.lastDeviceArray[i].racer_start_num,
            racer_id: this.lastDeviceArray[i].racer_id,
            racerStatus: this.lastDeviceArray[i].racer_status,
          });

          let carIcon2 = L.divIcon({
            iconAnchor: [8, 10],
            iconSize: [20],
            startnum: this.lastDeviceArray[i].racer_start_num,
            racer_id: this.lastDeviceArray[i].racer_id,
            className: "my-div-icon",
            html:
              '<b style="font-size:10.5px;">' +
              this.lastDeviceArray[i].racer_start_num +
              "</b>",
          });

          let Marker = L.marker(marker, {
            icon: carIcon,
            autoPanOnFocus: false,
            rotationAngle: this.lastDeviceArray[i].bearing,
          }).addTo(this.mapDiv);
          let Marker2 = L.marker(marker, {
            icon: carIcon2,
            autoPanOnFocus: false,
          })
            .addTo(this.mapDiv)
            .on("click", this.getActualRacerData);


          this.carMarkerArray.push(Marker);
          this.carMarkerArray2.push(Marker2);
        } else {
          this.carMarkerArray.push([]);
          this.carMarkerArray2.push([]);
        }
      }

      this.load = false;
    },

    async renderChart() {
      this.actualRacerGForcesRe = this.actualRacerGForces.reverse();
      this.actualRacerSpeedsRe = this.actualRacerSpeeds.reverse();
      this.modalChartLabelsRe = this.modalChartLabels.reverse();

      if (this.chart != undefined) {
        this.chart.destroy();
        this.chart = undefined;
      }

      if (this.chart2 != undefined) {
        this.chart2.destroy();
        this.chart2 = undefined;
      }
      if (this.$refs.lineChart != undefined) {
        const ctx1 = await this.$refs.lineChart.getContext("2d");
        this.chart = new Chart(ctx1, {
          type: "line",
          data: {
            labels: this.modalChartLabelsRe,
            datasets: [
              {
                label: "Speed",
                data: this.actualRacerSpeedsRe,
                backgroundColor: "rgba(75, 192, 192, 0.2)",
                borderColor: "#dd9c44",
                borderWidth: 2,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            labels: {
              fontColor: "white",
            },
            animation: {
              duration: 0,
            },
          },
        });
      }

      if (this.$refs.lineChart2 != undefined) {
        const ctx2 = await this.$refs.lineChart2.getContext("2d");
        this.chart2 = new Chart(ctx2, {
          type: "line",
          data: {
            labels: this.modalChartLabelsRe,
            datasets: [
              {
                label: "G-force",
                data: this.actualRacerGForcesRe,
                backgroundColor: "rgba(75, 192, 192, 0.2)",
                borderColor: "#dd9c44",
                borderWidth: 2,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            animation: {
              duration: 0,
            },
          },
        });
      }
    },

    closeRacer() {
      this.isSelected = false;
    },

    navToRacer() {
      localStorage.setItem("racer_id", this.selectedRacer.racer_id);
      this.$router.push("/racerinfo");
    },

    async getActualRacerData(marker) {
      this.currentMarker = marker;
      this.loading = true;
      this.isSelected = !this.isSelected;
      this.isSelected = true;
      this.actualRacerGForces = [];
      this.actualRacerSpeeds = [];
      this.modalChartLabels = [];
      try {
        this.actualLastData = await dp.getLogsBytimeandLimit(
          this.legId,
          this.limit,
          this.timestamp,
          marker.sourceTarget.options.icon.options.racer_id,
          this.legDate
        );

        this.selectedRacer = await dp.getRacersById(
          marker.sourceTarget.options.icon.options.racer_id
        );
        this.loading = false;

        for (let i = 0; i < this.actualLastData.length; i++) {
          this.actualRacerSpeeds.push(this.actualLastData[i].velocity);
          this.actualRacerGForces.push(this.actualLastData[i].g_force);
          this.modalChartLabels.push(
            DateTime.fromISO(this.actualLastData[i].recorded_at).toLocaleString(
              {
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hourCycle: "h23",
              }
            )
          );
        }
        this.reloadChartData();
      } catch (error) {
        console.error(error);
      }
    },

    poiFilter(item) {
      for (let i = 0; i < this.markerElements.length; i++) {
        let itemString = "icons/" + item + ".png";
        let variableString =
          item.toString().replace("-", "").toLowerCase() + "Show";
        if (this.markerElements[i].options.icon.options.iconUrl == itemString) {
          if (this[variableString] == true) {
            this.mapDiv.removeLayer(this.markerElements[i]);
            this[variableString] = false;
          } else if (this[variableString] == false) {
            this.markerElements[i].addTo(this.mapDiv);
            this[variableString] = true;
          }
        }
      }
    },
    formatDate(date) {
      const formattedDate = new Date(date).toLocaleDateString("en-CA");
      return formattedDate;
    },
    formatTimeDate(datetime) {
      const formattedDate = new Date(datetime).toLocaleString();
      return formattedDate;
    },
    navigateHomPage() {
      this.$router.push("/legs");
    },
    toggleDropDown() {
      this.isDropdownVisible = !this.isDropdownVisible;
    },
    logUserOut() {
      localStorage.removeItem("user_id");
      this.$router.push("/");
    },
    setLightLayer() {
      document.getElementById("mapContainer").style.backgroundColor = "#fafaf8";
      this.baseLayer = L.tileLayer(
        "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png",
        {
          maxZoom: 20,
          subdomains: ["mt0", "mt1", "mt2", "mt3"],
        }
      ).addTo(this.mapDiv);
    },
    setDarkLayer() {
      document.getElementById("mapContainer").style.backgroundColor = "#101010";
      this.baseLayer = L.tileLayer(
        "https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png",
        {
          maxZoom: 20,
          subdomains: ["mt0", "mt1", "mt2", "mt3"],
        }
      ).addTo(this.mapDiv);
    },
    setSatelliteLayer() {
      document.getElementById("mapContainer").style.backgroundColor = "#71705c";
      this.baseLayer = L.tileLayer(
        "https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}",
        {
          maxZoom: 20,
          subdomains: ["mt0", "mt1", "mt2", "mt3"],
        }
      ).addTo(this.mapDiv);
    },
    changeLayer(layer) {
      if (this.switchLayer != 0 && layer == 0) {
        this.mapDiv.removeLayer(this.baseLayer);
        this.setLightLayer();
      }
      if (this.switchLayer != 1 && layer == 1) {
        this.mapDiv.removeLayer(this.baseLayer);
        this.setDarkLayer();
      }
      if (this.switchLayer != 2 && layer == 2) {
        this.mapDiv.removeLayer(this.baseLayer);
        this.setSatelliteLayer();
      }
    },
    closePopup() {
      this.popUpId = 0;
      this.isOpen = false;
    },
    async dwellTime(id, value) {
      await dp.changeDwellTime(value, id);
      this.stages = await dp.getStageByLegId(this.legId);
    },

    setMapView(index) {
      let stageCoordData;
      if (this.stages[index]) {
        stageCoordData = JSON.parse(this.stages[index].stage_line);
      }

      let latlngs = stageCoordData.coordinates;

      if (this.highlightBorder != undefined) {
        this.mapDiv.removeLayer(this.highlightBorder);
      }

      let middleCoord = latlngs.length / 2;
      middleCoord = parseInt(middleCoord);

      // Az eltolás mértéke
      const offsetX = -0.008; // Az X irányú eltolás mértéke (pozitív érték jobbra, negatív balra)
      const offsetY = -0.015; // Az Y irányú eltolás mértéke (pozitív érték lefelé, negatív felfelé)

      // A térkép új középpontjának beállítása az eltolás hozzáadásával
      this.mapDiv.setView(
        [latlngs[middleCoord][0] + offsetX, latlngs[middleCoord][1] + offsetY],
        15
      );

      this.highlightBorder = L.polyline(latlngs, {
        color: "black",
        weight: "7",
        pane: "highlightPane",
      }).addTo(this.mapDiv);
      // A pulzáló hatás hozzáadása a borderhez
      this.pulseEffect(L.polyline, this.highlightBorder);
    },
    // set map status
    async setNormal(id) {
      await dp.addStageLog(undefined, id, "normal");
      this.stageLogs = await dp.getAllStageStatusLogById(this.legId);
      for (const [index, polyline] of this.polyLineArray.entries()) {
        // for of with index
        if (polyline.options.id == "stageLine" + id) {
          let element = document.getElementsByTagName(`path`)[index];
          element.setAttribute("stroke", "green");
          break;
        }
      }
    },
    async setRedFlag(id) {
      await dp.addStageLog(undefined, id, "red flag");
      this.stageLogs = await dp.getAllStageStatusLogById(this.legId);
      for (const [index, polyline] of this.polyLineArray.entries()) {
        // for of with index
        if (polyline.options.id == "stageLine" + id) {
          let element = document.getElementsByTagName(`path`)[index];
          element.setAttribute("stroke", "red");
          break;
        }
      }
    },

    //--------------------------------MQTT FUNCTIONS----------------------------------
    initMQTTData() {
      this.client = {
        connected: false,
      };
      this.retryTimes = 0;
      this.connecting = false;
      this.subscribeSuccess = false;
    },
    handleMQTTOnReConnect() {
      /* 
      this.retryTimes += 1; */
      console.log("Mqtt reconnecting");
      /* 
      if (this.retryTimes > 10) {
        try {
          if (this.client && this.client.connected) {
            this.client.end();
          }
          this.initMQTTData();
          this.client.reconnect()

        } catch (error) {
          console.error("Error during MQTT reconnect handling:", error);
        }
      } */
    },
    createMQTTConnection() {
      try {
        this.connecting = true;
        const { protocol, host, port, endpoint, ...options } = this.connection;
        const connectUrl = `${protocol}://${host}:${port}${endpoint}`;
        this.client = mqtt.connect(connectUrl, options);
        if (this.client.on) {
          this.client.on("connect", () => {
            this.connecting = false;
            console.log("Connection succeeded!");
          });
          this.client.on("reconnect", this.handleMQTTOnReConnect);
          this.client.on("error", (error) => {
            console.error("Connection failed", error);
          });
          this.client.on("message", (topic, message) => {
            if (topic.includes("device_logs")) {
              let parsedMessage = JSON.parse(message);

              if (this.replayRacerArray.includes(parsedMessage.racer_id)) {
                let lat = JSON.parse(parsedMessage.snapped_coord)
                  .coordinates[0];
                let lon = JSON.parse(parsedMessage.snapped_coord)
                  .coordinates[1];
                parsedMessage.snapped_coord = {};
                parsedMessage.snapped_coord.coordinates = [];
                /* this.timestamp = DateTime.fromISO(
                parsedMessage.recorded_at
              ).toMillis(); */

                parsedMessage.snapped_coord.coordinates[0] = lat;

                parsedMessage.snapped_coord.coordinates[1] = lon;
                let trailIndex = this.replayRacerArray.indexOf(
                  parsedMessage.racer_id
                );
                if (this.trailArray[trailIndex]) {
                  this.trailArray[trailIndex].unshift(parsedMessage);
                  //TODO itt is ki kell majd rajzolni a line-t
                  this.drawTrail(
                    undefined,
                    this.trailArray[trailIndex][1],
                    this.trailArray[trailIndex][0],
                    trailIndex
                  );
                  if (this.trailArray[trailIndex].length > this.trailLimit) {
                    this.trailArray[trailIndex].pop();
                  }
                }
                let racerIndex = -1;
                for (
                  let index = 0;
                  index < this.lastDeviceArray.length;
                  index++
                ) {
                  if (
                    this.lastDeviceArray[index].racer_id ==
                    parsedMessage.racer_id
                  ) {
                    racerIndex = index;
                    break;
                  }
                }
                if (racerIndex != -1) {
                  this.lastDeviceArray[racerIndex] = {
                    ...this.lastDeviceArray[racerIndex],
                    ...parsedMessage,
                  };

                  // Updating Status and changing color
                  let status = parsedMessage.racer_status;
                  let isTransporting = false;
                  if (parsedMessage.racer_status === "SOS") {
                    status = "SOS";
                  }else if (parsedMessage.racer_status === "stopped") {
                  status = "stopped";
                } else {
                    for (let index = 0; index < this.stages.length; index++) {
                      if (
                        this.stageLogs[index]?.stage_id ==
                          parsedMessage.closest_stage &&
                        this.stageLogs[index].stage_status == "transport"
                      ) {
                        isTransporting = true;
                        if (
                          parsedMessage.velocity >= 0 &&
                          parsedMessage.velocity <= 10
                        ) {
                          status = "stoppedTransport";
                        } else {
                          status = "movingTransport";
                        }
                        break;
                      }
                    }
                    if (!isTransporting) {
                      status = parsedMessage.racer_status;
                    }
                  }
                  let iconColor;
                  switch (status) {
                    case "normal":
                      iconColor = "black";
                      break;
                    case "SOS":
                      iconColor = "red";
                      break;
                    case "OK":
                      iconColor = "green";
                      break;
                    case "stoppedTransport":
                      iconColor = "grey";
                      break;
                    case "movingTransport":
                      iconColor = "white";
                      break;
                    case "stopped":
                      iconColor = "yellow";
                      break;
                    default:
                      iconColor = "black";
                      break;
                  }

                  let vehicleIcon = L.icon({
                    iconUrl: `icons/carIcons/${iconColor}.webp`,
                    racerStatus: parsedMessage.racer_status,
                    iconAnchor: [20, 20],
                    iconSize: [40, 40],
                    rotationAngle: parsedMessage.bearing,
                    rotationOrigin: "center",
                    startnum: this.lastDeviceArray[racerIndex].racer_start_num,
                    racer_id: this.lastDeviceArray[racerIndex].racer_id,
                  });
                  // updating Icon2 for marker
                  let vehicleIcon2 = L.divIcon({
                    iconAnchor: [8, 10],
                    iconSize: [20],
                    className: "my-div-icon",
                    startnum: this.lastDeviceArray[racerIndex].racer_start_num,
                    racer_id: this.lastDeviceArray[racerIndex].racer_id,
                    html:
                      '<b  style="font-size:10.5px;display:inline-block;text-align:center;vertical-align:middle;width:15px;height:15px;margin-top:4px; margin-right:4px; position: relative">' +
                      this.lastDeviceArray[racerIndex].racer_start_num +
                      "</b>",
                  });
                  // Refrehing Markers if they change in ssome attribute, like RotationAngle, ID, Coordinates, Status

                  if (
                    this.carMarkerArray[racerIndex].options.rotationAngle !=
                      parsedMessage.bearing ||
                    this.carMarkerArray[racerIndex].options.id !=
                      parsedMessage.racer_id ||
                    (this.carMarkerArray[racerIndex]._latlng.lat !=
                      parsedMessage.latitude &&
                      this.carMarkerArray[racerIndex]._latlng.lat !=
                        parsedMessage.snapped_coord.coordinates[0]) ||
                    (this.carMarkerArray[racerIndex]._latlng.lng !=
                      parsedMessage.longitude &&
                      this.carMarkerArray[racerIndex]._latlng.lng !=
                        parsedMessage.snapped_coord.coordinates[1])
                  ) {
                    //Updating RotationAngle on marker
                    this.carMarkerArray[racerIndex].options.rotationAngle =
                      parsedMessage.bearing;
                    // Updating LatLong on marker
                    this.carMarkerArray[racerIndex].setLatLng([
                      parsedMessage.stage_distance > range
                        ? parsedMessage.latitude
                        : parsedMessage.snapped_coord.coordinates[0],
                      parsedMessage.stage_distance > range
                        ? parsedMessage.longitude
                        : parsedMessage.snapped_coord.coordinates[1],
                    ]);
                    // Updating Icon on marker
                    this.carMarkerArray[racerIndex].setIcon(vehicleIcon);
                    // Updating id on marker

                    this.carMarkerArray[racerIndex].options.id =
                      parsedMessage.racer_id;

                    // Updating marker LATLNG on number marker
                    this.carMarkerArray2[racerIndex].setLatLng([
                      parsedMessage.stage_distance > range
                        ? parsedMessage.latitude
                        : parsedMessage.snapped_coord.coordinates[0],
                      parsedMessage.stage_distance > range
                        ? parsedMessage.longitude
                        : parsedMessage.snapped_coord.coordinates[1],
                    ]);
                    //updating Icon on number marker
                    this.carMarkerArray2[racerIndex].setIcon(vehicleIcon2);
                    //updating ID on number marker
                    this.carMarkerArray2[racerIndex].options.id =
                      parsedMessage.racer_id;
                    if (this.carMarkerArray2[racerIndex]._icon) {
                      if (
                        this.carMarkerArray2[racerIndex]._icon.style.display ==
                          "none" ||
                        this.carMarkerArray[racerIndex]._icon.style.display ==
                          "none"
                      ) {
                        this.carMarkerArray2[racerIndex]._icon.style.display =
                          "";
                        this.carMarkerArray[racerIndex]._icon.style.display =
                          "";
                      }
                    }
                  }
                  //-----------------Update chart-----------------------------------------
/* 
                  if (
                    this.chart &&
                    this.selectedRacer.racer_id ==
                      this.lastDeviceArray[racerIndex].racer_id
                  ) {
                    this.reloadChartData();
                  } */
                  if (this.chart) {
                  if (
                    (this.selectedRacer.racer_id ==
                      this.lastDeviceArray[racerIndex].racer_id &&
                      this.selectedRacer.racer_id != undefined) ||
                    (this.selectedRacer.staff_id ==
                      this.lastDeviceArray[racerIndex].staff_id &&
                      this.selectedRacer.staff_id != undefined)
                  ) {
                    this.reloadChartData();
                  }
                  }
                } else {
                  //ha nincs még marker
                  this.lastDeviceArray.push(parsedMessage);
                  let i = this.lastDeviceArray.length - 1;
                  let marker = [
                    parseFloat(this.lastDeviceArray[i].latitude),
                    parseFloat(this.lastDeviceArray[i].longitude),
                  ];

                  let status =
                    this.lastDeviceArray[i].racer_status ||
                    this.lastDeviceArray[i].staff_status;
                  let isTransporting = false;
                  if (this.lastDeviceArray[i].racer_status === "SOS") {
                    status = "SOS";
                  }else if (this.lastDeviceArray[i].racer_status === "stopped") {
                  status = "stopped";
                } else {
                    for (let index = 0; index < this.stages.length; index++) {
                      if (
                        this.stageLogs[index]?.stage_id ==
                          this.lastDeviceArray[i].closest_stage &&
                        this.stageLogs[index].stage_status == "transport"
                      ) {
                        isTransporting = true;
                        if (
                          this.lastDeviceArray[i].velocity >= 0 &&
                          this.lastDeviceArray[i].velocity <= 10
                        ) {
                          status = "stoppedTransport";
                        } else {
                          status = "movingTransport";
                        }
                        break;
                      }
                    }
                    if (!isTransporting) {
                      status =
                        this.lastDeviceArray[i].racer_status ||
                        this.lastDeviceArray[i].staff_status;
                    }
                  }
                  let iconColor;
                  switch (status) {
                    case "normal":
                      iconColor = "black";
                      break;
                    case "SOS":
                      iconColor = "red";
                      break;
                    case "OK":
                      iconColor = "green";
                      break;
                    case "stoppedTransport":
                      iconColor = "grey";
                      break;
                    case "movingTransport":
                      iconColor = "white";
                      break;
                    case "stopped":
                      iconColor = "yellow";
                      break;
                    default:
                      iconColor = "black";
                      break;
                  }

                  let carIcon = L.icon({
                    iconUrl: `icons/carIcons/${iconColor}.webp`,
                    iconAnchor: [20, 20],
                    iconSize: [40, 40],
                    rotationAngle: this.lastDeviceArray[i].bearing,
                    rotationOrigin: "center",
                    startnum: this.lastDeviceArray[i].racer_start_num,
                    racer_id: this.lastDeviceArray[i].racer_id,
                    racerStatus: this.lastDeviceArray[i].racer_status,
                  });
                  let carIcon2 = L.divIcon({
                    iconAnchor: [8, 10],
                    iconSize: [20],
                    startnum: this.lastDeviceArray[i].racer_start_num,
                    racer_id: this.lastDeviceArray[i].racer_id,

                    className: "my-div-icon",
                    html:
                      '<b  style="font-size:10.5px;display:inline-block;text-align:center;vertical-align:middle;width:15px;height:15px;margin-top:4px; margin-right:4px; position: relative">' +
                      this.lastDeviceArray[i].racer_start_num +
                      "</b>",
                  });

                  let Marker = L.marker(marker, {
                    icon: carIcon,
                    autoPanOnFocus: false,
                    rotationAngle: this.lastDeviceArray[i].bearing,
                  }).addTo(this.mapDiv);

                  let Marker2 = L.marker(marker, {
                    icon: carIcon2,
                    autoPanOnFocus: false,
                  })
                    .addTo(this.mapDiv)
                    .on("click", this.getActualRacerData);

                  this.carMarkerArray.push(Marker);
                  this.carMarkerArray2.push(Marker2);
                }
              }
            }

            /*            if (topic.includes("signalingLog")) {
               if (topic.includes("warnings")){
                if (this.sosWarnings.length <= 100) {
                this.sosWarnings.splice(0, 0, JSON.parse(message));
              } else {
                this.sosWarnings.splice(0, 0, JSON.parse(message));
                this.sosWarnings.pop();
              }
              }
              if (this.signalingLogs.length <= 100) {
                this.signalingLogs.splice(0, 0, JSON.parse(message));
              } else {
                this.signalingLogs.splice(0, 0, JSON.parse(message));
                this.signalingLogs.pop();
              }
              if (topic.includes("speeding")) {
                if (this.speedLimitLogs.length <= 100) {
                  this.speedLimitLogs.splice(0, 0, JSON.parse(message));
                } else {
                  this.speedLimitLogs.splice(0, 0, JSON.parse(message));
                  this.speedLimitLogs.pop();
                }
                //this.sosWarnings.push(JSON.parse(message))
              }
            }*/
          });
        }
      } catch (error) {
        this.connecting = false;
        console.error("mqtt.connect error", error);
      }
    },
    async reloadChartData() {
      if (this.selectedRacer != undefined) {
        this.actualLastData = await dp.getLogsBytimeandLimit(
          this.legId,
          this.selectedNumber,
          this.timestamp,
          this.selectedRacer.racer_id,
          this.legDate
        );
        this.actualRacerSpeeds = [];
        this.actualRacerGForces = [];
        this.modalChartLabels = [];
        for (let i = 0; i < this.actualLastData.length; i++) {
          this.actualRacerSpeeds.push(this.actualLastData[i].velocity);
          this.actualRacerGForces.push(this.actualLastData[i].g_force);
          this.modalChartLabels.push(
            DateTime.fromISO(this.actualLastData[i].recorded_at).toLocaleString(
              {
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hourCycle: "h23",
              }
            )
          );
        }
      }

      this.renderChart();
    },
    doMQTTSubscribe(topic, qos) {
      //const { topic, qos } = this.subscription;
      this.client.subscribe(topic, { qos }, (error, res) => {
        if (error) {
          console.error("Subscribe to topics error", error);
          return;
        }
        this.subscribeSuccess = true;
        console.log("Subscribe to topics res", res);
      });
    },
    doMQTTUnSubscribe(topic) {
      //const { topic } = this.subscription;
      this.client.unsubscribe(topic, (error) => {
        if (error) {
          console.error("UNSubscribe to topics error", error);
        }
      });
    },
    /* doMQTTPublish(topic, payload, qos) {
      //const { topic, qos, payload } = this.publish;
      this.client.publish(topic, payload, { qos }, (error) => {
        if (error) {
        }
      });
    }, */
    destroyMQTTConnection() {
      if (this.client.connected) {
        try {
          this.client.end(false, () => {
            this.initMQTTData();
            console.log("Successfully disconnected!");
          });
        } catch (error) {
          console.error("Disconnect failed", error.toString());
        }
      }
    },
    //---------------------------------------------------------MQTT ends here----------------------------------------
  },

  async unmounted() {
    await this.stopReplay();
    this.mapDiv.remove();
    this.destroyMQTTConnection();
  },
};
</script>

<style lang="scss" scoped>
.btn-close-white {
  background-color: white;
}
button,
input,
optgroup,
select,
textarea {
  border: none;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  background-color: #202528;
  color: white;
  text-decoration: white;
}

#timeSlider {
  width: 90%;
}

.custom-range::-webkit-slider-thumb {
  background-color: orange;
  --box-fill: calc(-100vmax - var(--thumb-width, var(--thumb-height))) 0 0
    100vmax currentColor;
}

.custom-range::-moz-range-thumb {
  background-color: orange;
}

.custom-range::-ms-thumb {
  background-color: orange;
}

.custom-range::-webkit-slider-runnable-track {
  background-color: grey;
  accent-color: orange;
}

.costum-range::-webkit-progress-value {
  background-color: orange;
}
.custom-range::-moz-range-progress {
  background: orange;
}

.custom-range::-ms-progress {
  background-color: orange;
}

.racerChk {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 30px;
  height: 30px;
  background-color: #202528;
  border: 1px solid orange;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
}

/* Style for checked state */
.racerChk:checked {
  background-color: orange;
  border: 5.5px solid orange;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  filter: invert(1); /* Invert the color of the clock icon */
}

.racerChkAll {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 30px;
  height: 30px;
  background-color: #202528;
  border: 1px solid orange;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
}

#replayMiddleBtn {
  bottom: 3px !important;
  position: relative;
}

/* Style for checked state */
.racerChkAll:checked {
  border: 5.5px solid rgb(234, 208, 11);
  background-color: rgb(234, 208, 11);
}
/* Style for tick mark */
.racerChkAll:checked::before {
  content: "\2713"; /* Unicode character for checkmark */
  font-size: 22px;
  color: white;
  position: relative;
  margin-left: 15%;
  top: -30%;
}

.racerChk:checked::before {
  content: "\2713"; /* Unicode character for checkmark */
  font-size: 22px;
  color: white;
  position: relative;
  margin-left: 20%;
  top: -30%;
}

#racerNumberReplay {
  text-align: center !important;
}

.slidecontainer {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding-top: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #202528;
}

.replayButtonRow {
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  background-color: #202528;
  color: white;
  justify-content: center;
  align-items: center;
  display: flex;
}

.replaySettingsBtn {
  background-color: rgb(238, 173, 8);
  margin-right: 2px;
  margin-left: 2px;
  margin-bottom: 5px;
}

.burgerOpenButton {
  display: none;
}

.replaySettingsBtn:hover {
  background-color: rgb(181, 122, 12);
}

.replayDiv {
  position: fixed;
  bottom: 5px;
  z-index: 1500;
  width: 65%;
  margin-left: 20%;
}

.fa-arrow-left,
.fa-2xl {
  font-size: 2rem;
}

.form-check-input {
  cursor: pointer;
  width: 25px;
  height: 25px;
  background-color: #202528;
  border: 1px solid #dd9c44;
}

.form-check-input:focus {
  outline: none;
  box-shadow: none;
}

#iconSizer {
  margin-top: 4%;
  margin-left: -35%;
  border-width: none;
}

#iconSizerBtn {
  background-color: #dd9c44;
  border-radius: 15px;
  border-style: none;
}

#iconSizerBtn:hover {
  transform: matrix(1.1);
  box-shadow: 0 0 10px #dd9c44;
  transition: 0.3s;
}

.form-check-input:checked {
  background-color: orange;
  border-color: orange;
}

.mapOption-popup {
  // min-height: 135px;
  width: 0vw;
  // min-width: 490px;
  background-color: #202528;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  left: 30px;
  float: left;
  z-index: 999999;
  visibility: visible;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-inline: 0;
}

.mapOption-popup-content {
  display: flex;
  flex-direction: column;
  background-color: #202528;
  width: 30vw;
  border-radius: 20px;
  padding-block: 5px;
  padding-inline: 0;
}

.spinner-container-big {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999999999999999;
  width: 100vw;
  height: 100vh;
  background: rgba(32, 37, 40, 0.1);
  pointer-events: auto;
}

#big-spinner {
  margin: 0 auto;
  height: auto;
  position: relative;
  width: auto;
  top: 40%;
}

#liveMapPage {
  position: absolute;
  top: 10vh;
  left: 0;
  width: 100vw;
  min-height: 100vh;
}

#liveMapPage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  overflow: hidden;
}

#livemappage::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, és Edge */
}

.poi-filter-container {
  width: 100%;
  scrollbar-width: thin;
  scrollbar-color: #dd9c44 transparent;
}

.mapOptionFlexBox {
  height: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.loading-title {
  color: #dd9c44;
  margin-top: 100px;
}

.backgroundOptionImg {
  display: flex;
  width: 8vw;
  height: 14vh;
  border-radius: 7.5%;
  // min-width: 153px;
  // min-height: 125px;
  margin-top: 4px;
  // margin-bottom: 10px;
}

.alert-text {
  font-size: 25px;
  font-family: "Lexend";
  color: #fff;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
}

#replay-label {
  font-size: 12px !important;
  font-family: "Lexend";
  color: #fff;
  margin-left: 10px !important;
  margin-right: 10px !important;
  margin-top: 10px !important;
}

#top-bar {
  position: relative;
  display: flex;
  margin: auto;
  z-index: 1010;
  visibility: visible;
  margin-top: 0;
  width: auto;
  height: 10.5%;
  background-color: rgb(73, 72, 72);
  color: #fff;
}

th {
  position: sticky;
  background-color: #dd9c44;
  color: black;
  border-bottom: 2px solid #202528;
  padding: 8px;
  text-align: left;
  font-family: "Lexend";
  padding-left: 2%;
  padding-right: 2%;
  top: 0;
  z-index: 1;
}

#checkInfo-btn {
  width: 35px;
  height: 35px;
  background-color: #dd9c44;
  border: 0;
  border-radius: 50%;
  color: black;
  margin-right: 10px;
}
#checkInfo-btn:hover {
  background-color: #b16b09;
}

td {
  border-bottom: 1px solid #202528;
  padding: 8px;
  text-align: left;
  color: white;
  font-family: "Lexend";
  padding-left: 2%;
  padding-right: 2%;
  background-color: #2c3033;
  cursor: auto;
}

.checkboxRow {
  width: 100%;
  text-align: left;
}

.cross {
  background-color: transparent;
  border: 0;
  height: 30px;
  outline: none;
}

.cross:active {
  background-color: transparent !important;
}
.cross:focus {
  outline: none;
}

.cross:hover {
  color: #dd9c44;
}

.center-alignment {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 2vh;
  padding: 0;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
}

.outer-table {
  width: 94%;
  height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
  /* Enable vertical scrolling */
  background-color: transparent;
  border: 2px solid rgba(255, 255, 255, 0);
  border-radius: 10px;
  scrollbar-width: thin;
  scrollbar-color: #dd9c44 transparent;
}

h1 {
  font-size: 25px;
  font-family: "Lexend";
  color: #fff;
}

h2 {
  font-size: 15px;
  font-family: "Lexend";
  color: #fff;
}

.back-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: #dd9c44;
  border-radius: 50%;
  border-color: transparent;
  height: 35px;
  width: 35px;
}

.back-button:hover {
  background-color: #e38e16;
  color: #2c3033;
}

.filter-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #dd9c44;
  border-radius: 10px;
  border-color: transparent;
  height: 3vh;
  width: auto;
}

.chartDiv2 {
  position: relative;
  width: 100%;
  height: 300px;
}

.chartDiv1 {
  width: 100%;
  height: 300px;
  position: relative;
}

#more-info-button {
  background-color: #dd9c44;
  border: 0;
  color: #202528;
}

#more-info-button:hover {
  color: #fff;
}
.data-popup {
  max-height: 80vh;
  width: 50vw;
  background-color: #202528;
  border-radius: 10px;
  float: left;
  top: 10%;
  z-index: 99999;
  visibility: visible;
  display: flex;
  flex-direction: column;
  position: relative;
  left: 100px;
  margin-top: 8vh;
}

.replay-title {
  font-size: 25px;
  font-family: "Lexend";
  color: #fff;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
}

.dropdown {
  top: 100%;
  margin-top: 5px;
  position: absolute;
  background-color: rgb(73, 72, 72);
  box-shadow: 0px 0px 8px #dd9c44;
}

.dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.racer-title-row {
  display: flex;
  width: 100%;
}

.racer-title {
  justify-content: center;
  align-items: center;
  width: 95%;
}

#close-info {
  float: right;
  right: 0;
  position: relative;
  width: 8%;
  background-color: transparent;
  border: 0;
}

.dropdown li {
  padding: 8px 16px;
  cursor: pointer;
}

.dropdown li:hover {
  background-color: #dd9c44;
}

/* Show the dropdown when isDropdownVisible is true */

#top-icon2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 30px;
}

#top-icon2:hover {
  color: #dd9c44;
}

#top-icon:hover {
  color: #dd9c44;
}

#right-icons {
  position: relative;
  float: left;
  display: flex;
}

#flag {
  background-image: url(../assets/flag.png);
  background-repeat: no-repeat;
  background-size: 120px 70px;
  position: relative;
  margin-top: 7.5%;
  margin-left: 7.5%;
  margin-right: 5%;
  height: 70px;
  width: 125px;
}
.green-flag {
  color: green;
}

.red-flag {
  color: red;
}

.yellow-flag {
  color: yellow;
}

.flag-class:hover {
  transform: scale(1.1);
  transition: 0.1s;
}
#trackInfo {
  max-width: 100%;
  padding-left: 20px;
}

#top-icons {
  float: right;
  position: relative;
  display: flex;
}

.fa-bell {
  margin-top: 10%;
  font-size: 500%;
}

.fa-circle-user {
  margin-left: -40%;
  font-size: 600%;
}

.selected-racer-modal {
  max-height: 500px;
  width: 400px;
  position: absolute;
  right: 80px;
  top: 10vh;
  background-color: #202528;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.25);
  z-index: 9999999999;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.racer-data {
  text-align: left;
  width: auto;
  font-size: 12px;
}

.sidebar {
  position: absolute;
  z-index: 1100;
  float: left;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // align-ites: center;
  height: 100vh;
  background-color: #202528;
  padding-right: 10px;
  padding-left: 0.7vw;
  box-shadow: 0 0 10px #dd9c44;
}

.sidebarBtns {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgb(61, 57, 57, 0.2);
  border-radius: 10px;
}

#blackCircleButtons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  // align-items: center;
}

#bc1 {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

#bc2 {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

#bc3 {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

#bc4 {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

#bc5 {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

#bc6 {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

#bc9 {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
.custom-time-input {
  z-index: 999999;
  cursor: auto;
}

.spinner-container {
  width: 100%;
  min-height: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.spinner-container {
  width: 100%;
  min-height: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

#spinner {
  margin: auto;
}

.poi-filter-container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  color: white;
}

.poi-filter-title {
  position: relative;
  float: left;
  left: 0;
  text-align: left;
}

.poi-filter-icon {
  height: 35px;
  ///width: 35px;
  padding-left: 5px;
}

.offcanvasRight {
  z-index: 99999;
}

#measure-btn {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 10100;
  background-color: black;
  border-radius: 5px;
  color: white;
  border: 3px solid white;
}

#measure-btn:hover {
  background-color: #dd9d45;
  color: black;
}

.functionBtns {
  max-width: 94%;
  margin-left: 1.5%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding-inline: 1vw;
  padding-top: 1vh;
  padding-bottom: 2vh;
}

#CheckSectionData-btn {
  margin-right: 5px;
  position: relative;
  z-index: 100;
  background-color: #dd9c44;
  border-radius: 5px;
  color: black;
  width: 50px;
  height: 40px;
}

#CheckSectionData-btn:hover {
  background-color: #b16b09;
  color: white;
}

#CheckTripData-btn {
  margin-right: 5px;
  position: relative;
  z-index: 100;
  background-color: #dd9c44;
  border-radius: 5px;
  color: black;
  width: 50px;
  height: 40px;
}

#CheckTripData-btn:hover {
  background-color: #b16b09;
  color: white;
}

#trailBtn {
  margin-right: 5px;
  position: relative;
  z-index: 100;
  background-color: #dd9c44;
  border-radius: 5px;
  color: black;
  width: 50px;
  height: 40px;
}

#trailBtn:hover {
  background-color: #b16b09;
  color: white;
}

#infoBtn {
  position: absolute;
  right: 1px;
  z-index: 100;
}

.offcanvas.show:not(.hiding),
.offcanvas.showing {
  transform: none;
  z-index: 999999;
}

#CheckInfoData-btn {
  width: 35px;
  height: 35px;
  background-color: #dd9c44;
  border: 0;
  border-radius: 50%;
  color: black;
  margin-left: auto;
  margin-right: 10px;
}

#CheckInfoData-btn:hover {
  background-color: #e38e16;
}

#RadiusField {
  margin-right: 5px;
  position: relative;
  width: 50px;
  height: 40px;
  z-index: 9999999;
  background-color: black;
  border: 0;
  border-radius: 5px;
  color: orange;
}

#RadiusField::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

#total-distance {
  position: absolute;
  top: 10px;
  height: 50px;
  right: 100px;
  z-index: 9999999;
  background-color: #202528;
  color: white;
  padding-bottom: 40px;
}

#search-field {
  position: absolute;
  z-index: 1000;
  top: 10px;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: auto;
  display: block;
}

.num-td {
  width: 30%;
  align-items: center;
  justify-content: center;
  max-height: 30px;
  vertical-align: middle;
}

.name-td {
  width: 70%;
  align-items: right;
  justify-content: right;
  max-height: 30px;
  vertical-align: middle;
}

.search-bar {
  background-color: rgba(44, 48, 51);
  color: white;
  max-width: 300px;
  border-radius: 15px;
  padding-left: 10px;
  padding-top: 1px;
  padding-bottom: 1px;
  border: 0;
  height: 40px;
}

.right-btns {
  display: flex;
  flex: 1 1 0%;
  justify-content: right;
}

.left-search {
  margin-right: 155px;
  display: flex;
  position: relative;
}

.search-icon2 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 5px;
  font-size: 1.2em;
  color: white;
}

.search-bar2 {
  background-color: rgb(44, 48, 51);
  color: white;
  max-width: 25vw;
  border-radius: 15px;
  padding-left: 30px;
  padding-top: 1px;
  padding-bottom: 1px;
  border: 0;
  height: 40px;
}

.search-result-tr {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.search-result-tr:hover td {
  background-color: #dd9c44;
  cursor: pointer;
  color: #2c3033;
}
.search-result-container {
  position: absolute;
  left: 10px;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 300px;
  top: 6vh;

  scrollbar-width: thin;
  scrollbar-color: #dd9c44 transparent;

  border-radius: 10px !important;
  overflow-y: scroll;
  border: 0;
  height: auto;
  max-height: 30vh;
}

#result-table {
  width: 100%;
  border-radius: 15px;
  height: 100%;
}

#result-table th {
  min-width: 50px !important;
  max-width: 50px !important;
}

.search-result-container {
  background-color: rgba(44, 48, 51, 0.85);
}

::placeholder {
  color: rgb(190, 184, 184);
  opacity: none;
}

.search-icon {
  margin-left: -30px;
}

#mapContainer {
  z-index: 1;
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  top: 0;
}
.status-text {
  text-align: left;
  width: 40px;
}
.button-margin {
  margin-top: 5vh;
}

.highlighted-tr td {
  background-color: #ffd062 !important;
  /* Set background color for td elements */
}

.sidebarBurger {
  display: none;
}

@media only screen and (max-width: 1700px) {
  #top-icons {
    margin-right: 5%;
  }

  #top-bar {
    height: auto;
  }
}

@media only screen and (max-height: 950px) {
  #top-bar {
    height: auto;
  }

  #flag {
    display: none;
  }

  #top-icons {
    float: right;
    margin-right: 10vh;
  }
}

@media only screen and (max-width: 1550px) {
  .left-search {
    margin-right: 130px;
  }
  .functionBtns {
    padding-inline: none;
    margin-left: 4%;
  }
  #timeSlider {
    width: 70%;
  }

  .backgroundOptionImg {
    height: 12vh;
  }
}

@media only screen and (max-width: 1200px) {
  .functionBtns {
    margin-left: 3%;
  }
  .data-popup {
    margin-left: 10px;
    width: 80vw !important;
  }
  .search-bar2 {
    width: 20vw;
  }
  .backgroundOptionImg {
    width: 8.5vw;
    height: 11vh;
  }

  #top-icons {
    margin-right: 7vh;
  }

  .fa-bell {
    margin-top: -20%;
    font-size: 250%;
  }

  .fa-circle-user {
    margin-top: -20%;
    font-size: 250%;
  }

  #flag {
    height: 100%;
    width: 10%;
  }

  .data-popup {
    width: 65vw !important;
  }
  .data-popup {
    left: 80px;
  }
}

@media (max-width: 1000px) {
  .poi-filter-icon {
    height: 30px;
    width: 30px;
  }
  .backgroundOptionImg {
    height: 8vh;
  }

  .mapOption-popup {
    height: 9vh;
  }
  .mapOption-popup {
    left: 10px;
  }
  .data-popup {
    margin-left: 10px;
    width: 80vw !important;
  }
  .mapOption-popup-content {
    width: 78vw;
  }
}

@media (max-width: 768px) {
  .left-search {
    display: none;
  }
  .form-check-input {
    width: 20px;
    height: 20px;
  }
  #search-field {
    display: none;
  }

  .burgerOpenButton {
    display: flex;
  }
  .sidebar {
    display: none;
  }
  .sidebarBurger {
    position: absolute;
    z-index: 1100;
    float: left;
    flex-direction: column;
    // justify-content: center;
    // align-ites: center;
    height: 100vh;
    background-color: #202528;
    padding-right: 1vw;
    padding-left: 0.7vw;
    box-shadow: 0 0 10px #dd9c44;
    display: flex;
    transition: 0.4s;
  }

  #poi-text {
    font-size: 16px;
  }

  #timeSlider {
    width: 40%;
  }

  .data-popup {
    width: 75vw !important;
  }
  .data-popup {
    left: 80px;
  }

  .backgroundOptionImg {
    width: 22vw;
    height: 16vh;
  }

  .backgroundOption .back-button {
    height: 30px;
    width: 30px;
  }
}

@media (max-width: 650px) {
  .replayButtonRow {
    display: none;
  }
  .mapOption-popup {
    height: 11vh;
  }

  .backgroundOptionImg {
    height: 10vh;
  }

  .offcanvas.show:not(.hiding),
  .offcanvas.showing {
    transform: none;
    z-index: 999999999;
  }

  .outer-table {
    position: relative;

    height: 60vh;
  }
  .data-popup {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw !important;
    max-height: 100vh !important;
    z-index: 99999999;
    margin-top: 0vh;
    border-radius: 0px;
    overflow-y: hidden;
    margin: 0;
    padding: 0;
  }
  .center-alignment {
    margin-bottom: 2vh;
    padding: 0;
    height: 100vh;
    top: 0;
  }
  .poi-filter-container {
    width: 100%;
    height: 100vh;
    overflow-y: hidden;
    color: white;
    border-radius: 0;
  }
}
@media (max-width: 460px) {
  .right-btns {
    margin-right: 15%;
  }
  .status-text {
    display: none;
  }
}

@media (max-width: 1100px) {
  th {
    font-size: 10px;
  }

  td {
    font-size: 10px;
  }
}

@media (max-width: 920px) {
  th {
    font-size: 9px;
  }

  td {
    font-size: 9px;
  }
}

@media (max-height: 750px) {
  .button-margin {
    margin-top: 3vh;
    transition: 0.5s;
  }
}
</style>
